import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const Debate: TypePolicy = {
  fields: {
    url: {
      read(_, { readField }) {
        return `/debates/${readField("slug")}`;
      },
    },
    follows: {
      merge(existing = {}, incoming = {}) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
    postsInFavor: relayStylePagination(),
    postsAgainst: relayStylePagination(),
    posts: relayStylePagination(["sortBy"]),
  },
};
