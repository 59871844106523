import { CreateUserPostMutationVariables, useCreateUserPostMutation } from "@/graphql/types";

export const useProfilePostsStore = () => {
  const [createPost, { loading }] = useCreateUserPostMutation({
    refetchQueries: ["GetUserPosts", "ProfileTimeline"],
  });

  return {
    loading,
    createPost({ data, id }: CreateUserPostMutationVariables) {
      return createPost({
        variables: {
          data,
          id,
        },
      });
    },
  };
};
