import { useAuthenticationStore } from "@/authentication/hooks";

import {
  EventInput,
  GetEventsQuery,
  GetEventsQueryVariables,
  GetEventsDocument,
  UserFullFragment,
  useGetEventsQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} from "@/graphql/types";

type UseEventsStoreProps = {
  slug?: string;
  fetch?: boolean;
  variables?: {
    expired?: boolean;
    user?: UserFullFragment;
    limit: number;
  };
  fetchBySlug?: boolean;
};

export const useEventsStore = ({
  slug,
  fetch,
  variables,
  fetchBySlug,
}: UseEventsStoreProps = {}) => {
  const { session } = useAuthenticationStore();
  const {
    loading: fetchLoading,
    data,
    error: fetchError,
  } = useGetEventQuery({ skip: !slug, variables: { slug: slug as string } });

  const {
    loading: fetchAllLoading,
    data: dataAll,
    error: fetchAllError,
    fetchMore: fetchNextEventsPage,
  } = useGetEventsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !fetch,
    variables: variables
      ? fetchBySlug
        ? {
            authorSlug: variables.user
              ? variables.user.slug
              : session?.user
              ? session.user.slug
              : "politicall",
            expired: variables.expired,
            limit: variables.limit,
          }
        : {
            authorId: variables.user
              ? variables.user.id
              : session?.user
              ? session.user.id
              : "politicall",
            expired: variables.expired,
            limit: variables.limit,
          }
      : undefined,
  });

  const [createEvent, { loading: createLoading }] = useCreateEventMutation();
  const [updateEvent, { loading: updateLoading }] = useUpdateEventMutation();
  const [deleteEvent, { loading: deleteLoading }] = useDeleteEventMutation();

  return {
    get event() {
      if (data) {
        return data.event;
      }
    },
    get events() {
      if (dataAll) {
        return dataAll.events.edges.map(({ node }) => node);
      }
      return [];
    },
    fetchNextEventsPage() {
      if (fetchNextEventsPage && dataAll) {
        return fetchNextEventsPage({
          variables: {
            after: dataAll.events.pageInfo.endCursor,
          },
        });
      }
    },
    create(data: EventInput) {
      return createEvent({
        variables: {
          data,
        },
        update(cache, { data }) {
          if (session && session.user) {
            const result = cache.readQuery<GetEventsQuery, GetEventsQueryVariables>({
              query: GetEventsDocument,
              variables: {
                limit: 6,
                authorId: session.user.id,
                expired: false,
              },
            });

            if (result) {
              cache.writeQuery({
                query: GetEventsDocument,
                variables: {
                  limit: 6,
                  authorId: session.user.id,
                  expired: false,
                },
                data: {
                  ...result,
                  events: {
                    ...result.events,
                    edges: [
                      ...result.events.edges,
                      {
                        node: data?.createEvent?.result,
                      },
                    ],
                  },
                },
              });
            }
          }
        },
      }).then(({ data }) => {
        if (data?.createEvent?.successful) {
          return data?.createEvent?.result;
        } else {
          throw data?.createEvent;
        }
      });
    },
    update(eventId: string, data: EventInput) {
      return updateEvent({
        variables: {
          id: eventId,
          data,
        },
      });
    },
    destroy(eventId: string) {
      return deleteEvent({
        variables: {
          id: eventId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: eventId,
            __typename: "Event",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    get loading() {
      return fetchAllLoading || fetchLoading || createLoading || updateLoading || deleteLoading;
    },
    get error() {
      return fetchError !== undefined || fetchAllError !== undefined;
    },
    get hasMorePages() {
      if (dataAll) {
        return dataAll.events.pageInfo.hasNextPage;
      }
      return false;
    },
  };
};
