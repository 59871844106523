import {
  useHintSetLazyQuery,
  HintSetQueryVariables,
  useUpdateUserHintSetsMutation,
  UpdateUserHintSetsMutationVariables,
  SessionFragment,
  GetSessionProfileDocument,
} from "@/graphql/types";

export const useTourStore = () => {
  const [fetchHintSet, { loading, data: hintSetData }] = useHintSetLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [updateUserHintSets] = useUpdateUserHintSetsMutation();

  return {
    loading,
    hints: hintSetData?.hintSet?.hints || [],
    fetchHintSet({ topic }: HintSetQueryVariables) {
      return fetchHintSet({ variables: { topic } });
    },
    updateUserHintSets({ topic }: UpdateUserHintSetsMutationVariables) {
      return updateUserHintSets({
        variables: {
          topic,
        },
        update(cache, { data }) {
          if (data?.updateUserHintSets?.successful) {
            const prevProfile = cache.readQuery<{ session: SessionFragment }>({
              query: GetSessionProfileDocument,
            });

            if (prevProfile?.session?.user) {
              cache.writeQuery({
                query: GetSessionProfileDocument,
                data: {
                  ...prevProfile,
                  session: {
                    ...prevProfile.session,
                    user: {
                      ...prevProfile.session.user,
                      visitedHintSetTopics: data.updateUserHintSets.result?.visitedHintSetTopics,
                    },
                  },
                },
              });
            }
          }
        },
      });
    },
  };
};
