import {
  EventCardFragment,
  EventCardSlimFragment,
  EventFragment,
  useReportEventMutation,
} from "@/graphql/types";

export const useEventsReportsStore = (
  event: EventFragment | EventCardSlimFragment | EventCardFragment,
) => {
  const [report] = useReportEventMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: event.id,
            type,
            comment,
          },
        }).then(({ data }) => {
          if (data?.reportEvent?.successful) {
            resolve(data?.reportEvent?.result);
          } else {
            reject(data?.reportEvent);
          }
        });
      });
    },
  };
};
