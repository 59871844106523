import { useState } from "react";

import { PetitionFragment, FileDataFragment, useUploadFileMutation } from "@/graphql/types";

export const usePetitionsUploadsStore = (petition?: PetitionFragment | null) => {
  const [uploadFile] = useUploadFileMutation();
  const [file, setFile] = useState<FileDataFragment | null | undefined>(
    petition ? petition.document : null,
  );

  return {
    uploadFile(file: File) {
      return uploadFile({
        variables: { file },
      }).then(({ data }) => {
        setFile(data?.uploadFile?.result);
      });
    },
    deleteFile() {
      setFile(null);
    },
    file,
    get fileId() {
      return file?.id;
    },
  };
};
