import { DependencyList, useEffect } from "react";

import { Subject } from "rxjs";

export function useUnsubscribe(unsubscribeSubject: Subject<any>, deps?: DependencyList) {
  useEffect(() => {
    return () => {
      unsubscribeSubject.next(undefined);
    };
  }, deps || []);
}
