import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import { useProfileFollowersStore } from "@/profile/hooks";

import { UserListEntity } from "@/common/models/UserListEntity";
import { UserBaseFragment } from "@/graphql/types";

import { Button } from "./button/Button";

const useStyles = makeStyles()((theme: Theme) => ({
  followActionText: {
    backgroundColor: theme.mode.background.light,
    fontSize: "1.0625rem",
    fontWeight: 800,
    padding: theme.spacing(0.75, 1.5),
    margin: theme.spacing(-0.75, -1.5),
    lineHeight: "normal",
    minHeight: "auto",
    borderRadius: "9px",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.mode.background.default,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.9375rem",
      zIndex: 10,
    },
    "&:disabled": {
      cursor: "default",
      "&:hover": {
        backgroundColor: "unset",
      },
    },
  },
}));
const FollowUserButton = ({ user }: { user: UserListEntity }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { toggleFollow, loadingToggleFollow } = useProfileFollowersStore({
    user: user as UserBaseFragment,
  });

  if (user.isMe) {
    return null;
  }

  return (
    <Button
      className={classes.followActionText}
      variant={
        user.follows?.isFollowedByMe || user.follows?.hasBeenRequestedByMe || loadingToggleFollow
          ? "inline-secondary"
          : "inline-primary"
      }
      onClick={toggleFollow}
      disabled={loadingToggleFollow || user.follows?.hasBeenRequestedByMe}>
      {loadingToggleFollow ? (
        <span>
          {user.follows?.hasBeenRequestedByMe || user.follows?.isFollowedByMe
            ? `${t("Generic.Unfollowing").toUpperCase()}...`
            : `${t("Generic.Following").toUpperCase()}...`}
        </span>
      ) : (
        <span>
          {user.follows?.hasBeenRequestedByMe
            ? t("Generic.Requested").toUpperCase()
            : user.follows?.isFollowedByMe
            ? t("Discover.UnfollowText").toUpperCase()
            : t("Discover.FollowText").toUpperCase()}
        </span>
      )}
    </Button>
  );
};
export default FollowUserButton;
