import React, { createContext, ComponentProps, FC, useContext, useState, useEffect } from "react";

import { ONESIGNAL_APP_ID, ONESIGNAL_SAFARI_ID } from "@config/env";
import OneSignal from "react-onesignal";

import { useAuthenticationStore } from "@/authentication/hooks";

interface OneSignalProviderInterface {
  isSubscriptionOn: boolean;
  showPrompt: () => void;
  removeSubscription: () => void;
}

export const OneSignalContext = createContext<OneSignalProviderInterface>(
  {} as OneSignalProviderInterface,
);

declare type PushSubscriptionNamespaceProperties = {
  id: string | null | undefined;
  token: string | null | undefined;
  optedIn: boolean;
};
declare type SubscriptionChangeEvent = {
  previous: PushSubscriptionNamespaceProperties;
  current: PushSubscriptionNamespaceProperties;
};

export const OneSignalProvider = ({ children }: ComponentProps<FC>) => {
  const [isSubscriptionOn, setIsSubscriptionOn] = useState<boolean>(false);
  const { session } = useAuthenticationStore();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (session?.user.id) {
      const login = async () => {
        await OneSignal.login(session.user.id);
      };

      login();
      setIsSubscriptionOn(OneSignal.User.PushSubscription.optedIn || false);
    } else {
      return;
    }
    const initializeOneSignal = async () => {
      await OneSignal.init({
        appId: ONESIGNAL_APP_ID,
        safari_web_id: ONESIGNAL_SAFARI_ID,
        allowLocalhostAsSecureOrigin: false,
        autoResubscribe: true,
        promptOptions: {
          showCredit: false,
          slidedown: {
            enabled: false,
            autoPrompt: false,
          },
        },
        notifyButton: {
          enable: false,
        },
      });
      setIsInitialized(true);
    };

    if (!isInitialized) initializeOneSignal();

    const handleChange = (change: SubscriptionChangeEvent) => {
      if (change.current.token && change.current.optedIn && session.user.id) {
        OneSignal.login(session.user.id);
      }
      setIsSubscriptionOn(change.current.optedIn);
    };

    OneSignal.User.PushSubscription.addEventListener("change", handleChange);

    return () => {
      OneSignal.User.PushSubscription.removeEventListener("change", handleChange);
    };
  }, [session?.user.id]);

  const showPrompt = async () => {
    console.log("show prompt for notifications");
    await OneSignal.Notifications.requestPermission();

    const permission = Notification.permission;
    if ((permission === "default" || permission === "granted") && session?.user.id) {
    } else if (permission === "denied") {
      console.log("Notifications are blocked by the user.");
    } else if (permission === "granted") {
      console.log("Notifications are already granted.");
    }
  };

  const removeSubscription = async () => {
    console.log("logging out of notifications");
    await OneSignal.User.PushSubscription.optOut();
  };

  return (
    <OneSignalContext.Provider value={{ isSubscriptionOn, showPrompt, removeSubscription }}>
      {children}
    </OneSignalContext.Provider>
  );
};

export const useOneSignalContext = () => useContext(OneSignalContext);
