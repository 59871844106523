import { useUserGroupsQuery } from "@/graphql/types";

type GroupOptionProps = {
  text: string;
  value: string;
};

export const useProfileGroupsStore = () => {
  const { loading, data } = useUserGroupsQuery();

  return {
    loading,
    groupOptions: (data?.userGroups?.map((group) => ({ text: group?.title, value: group?.id })) ||
      []) as GroupOptionProps[],
  };
};
