import { useSubscription } from "@apollo/client";

import {
  GroupFragment,
  GroupTimelineItemsQuery,
  GroupTimelineItemsQueryVariables,
  GroupTimelineItemsDocument,
  PostInput,
  ItemsOrder,
  GroupAddedItemsCountDocument,
  useGroupTimelineItemsLazyQuery,
  useCreateGroupPostMutation,
} from "@/graphql/types";
import { PostsOrderOption } from "@/posts/models/PostsOrderOption";

export const useGroupsTimelineStore = (group: GroupFragment) => {
  const [
    fetchTimeline,
    { data: timelimeData, networkStatus, fetchMore: fetchNextTimelinePage, refetch },
  ] = useGroupTimelineItemsLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });
  const { data: unreadPostsCountData } = useSubscription(GroupAddedItemsCountDocument, {
    variables: {
      id: group.id,
      lastSeenItemAt: timelimeData?.groupTimelineItems.edges[0]?.node.createdAt,
    },
    skip: networkStatus === 1,
  });
  const [createPost] = useCreateGroupPostMutation();

  return {
    timelineItems:
      timelimeData?.groupTimelineItems?.edges.map(
        ({ node, pinned }) => ({ ...node, pinned } as const),
      ) || [],
    unreadPostsCount: unreadPostsCountData?.groupAddedItemsCount || 0,
    loading: networkStatus === 1,
    loadingRefetch: networkStatus === 4,
    hasMorePages: timelimeData?.groupTimelineItems.pageInfo.hasNextPage || false,
    fetchTimeline({ order }: { order?: PostsOrderOption }) {
      return fetchTimeline({
        variables: {
          id: group.id,
          sortBy:
            order === PostsOrderOption.MostRecent ? ItemsOrder.NewestFirst : ItemsOrder.OldestFirst,
        },
      });
    },
    refetch() {
      return refetch && refetch();
    },
    createPost(data: PostInput) {
      return createPost({
        variables: {
          groupId: group.id,
          data,
        },
        update: (cache, { data }) => {
          if (!data?.createGroupPost?.result) return;
          const result = cache.readQuery<GroupTimelineItemsQuery>({
            query: GroupTimelineItemsDocument,
            variables: {
              id: group.id,
              sortBy: ItemsOrder.NewestFirst,
            },
          });

          if (result && result.groupTimelineItems) {
            const newData: GroupTimelineItemsQuery = {
              ...result,
              groupTimelineItems: {
                ...result.groupTimelineItems,
                edges: [
                  { node: data.createGroupPost.result, pinned: data.createGroupPost.result.pinned },
                  ...result.groupTimelineItems.edges,
                ],
              },
            };
            cache.writeQuery<GroupTimelineItemsQuery, GroupTimelineItemsQueryVariables>({
              query: GroupTimelineItemsDocument,
              variables: {
                id: group.id,
                sortBy: ItemsOrder.NewestFirst,
              },
              data: newData,
            });
          }
        },
      });
    },
    fetchNextTimelinePage() {
      if (fetchNextTimelinePage && timelimeData?.groupTimelineItems) {
        return fetchNextTimelinePage({
          variables: {
            after: timelimeData.groupTimelineItems.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
