import { useAuthenticationStore } from "@/authentication/hooks";

import {
  GetGroupsDocument,
  GetGroupsQuery,
  GetGroupsQueryVariables,
  GroupInput,
  useGetGroupQuery,
  UserFullFragment,
  DeleteGroupMutationVariables,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useTriggerGroupTimelineItemPinMutation,
  GroupTimelineItemType,
} from "@/graphql/types";

type UseGroupsStoreProps = {
  slug?: string;
  id?: string;
  fetch?: boolean;
  variables?: {
    user?: UserFullFragment;
    limit: number;
  };
  fetchBySlug?: boolean;
};

export const useGroupsStore = ({
  slug,
  id,
  fetch,
  variables,
  fetchBySlug,
}: UseGroupsStoreProps = {}) => {
  const { session } = useAuthenticationStore();
  const {
    loading: fetchLoading,
    data,
    error: fetchError,
  } = useGetGroupQuery({ skip: !(slug || id), variables: { slug, id } });

  const {
    loading: fetchAllLoading,
    data: dataAll,
    error: fetchAllError,
    fetchMore: fetchNextGroupsPage,
  } = useGetGroupsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !fetch,
    variables: variables
      ? fetchBySlug
        ? {
            authorSlug: variables.user
              ? variables.user.slug
              : session?.user
              ? session.user.slug
              : "politicall",
            limit: variables.limit,
          }
        : {
            authorId: variables.user
              ? variables.user.id
              : session?.user
              ? session.user.id
              : "politicall",
            limit: variables.limit,
          }
      : undefined,
  });
  const [createGroup, { loading: createLoading }] = useCreateGroupMutation();
  const [updateGroup, { loading: updateLoading }] = useUpdateGroupMutation();
  const [deleteGroup, { loading: deleteLoading }] = useDeleteGroupMutation();

  const [triggerGroupItemPin] = useTriggerGroupTimelineItemPinMutation({
    refetchQueries: ["GroupTimelineItems"],
  });

  return {
    loading: fetchAllLoading || fetchLoading || createLoading || updateLoading || deleteLoading,
    error: fetchError !== undefined || fetchAllError !== undefined,
    hasMorePages: dataAll?.groups.pageInfo.hasNextPage || false,
    triggerGroupItemPin(id: string, groupType: GroupTimelineItemType) {
      return triggerGroupItemPin({ variables: { id, itemType: groupType } });
    },
    group: data?.group,
    groups: dataAll?.groups.edges.map(({ node }) => ({ ...node } as const)) || [],
    fetchNextGroupsPage() {
      if (fetchNextGroupsPage && dataAll) {
        return fetchNextGroupsPage({
          variables: {
            after: dataAll.groups.pageInfo.endCursor,
          },
        });
      }
    },
    create(data: GroupInput) {
      return createGroup({
        variables: {
          data,
        },
        update(cache, { data }) {
          if (session && session.user) {
            const result = cache.readQuery<GetGroupsQuery, GetGroupsQueryVariables>({
              query: GetGroupsDocument,
              variables: {
                authorId: session.user.id,
                limit: 6,
              },
            });

            if (result) {
              cache.writeQuery({
                query: GetGroupsDocument,
                variables: {
                  authorId: session.user.id,
                  limit: 6,
                },
                data: {
                  ...result,
                  groups: {
                    ...result.groups,
                    edges: [
                      ...result.groups.edges,
                      {
                        node: data?.createGroup?.result,
                      },
                    ],
                  },
                },
              });
            }
          }
        },
      }).then(({ data }) => data?.createGroup?.result);
    },
    update(groupId: string, data: GroupInput) {
      return updateGroup({
        variables: {
          id: groupId,
          data,
        },
      });
    },
    destroy({ id, unlinkActivities }: DeleteGroupMutationVariables) {
      return deleteGroup({
        variables: {
          id,
          unlinkActivities,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id,
            __typename: "Group",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
  };
};
