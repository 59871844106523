import appSettings from "@/common/constants/appSettings";
import { colorModes } from "@/common/constants/colorModes";
import { palettes } from "@/common/constants/palettes";
import { PREDEFINED_AVATAR_NAMES, PREDEFINED_COVER_NAMES } from "@/common/constants/profilePresets";
import { ResourceType } from "@/graphql/types";

export { appSettings, palettes, colorModes, PREDEFINED_AVATAR_NAMES, PREDEFINED_COVER_NAMES };

export * from "@config/env";

export const USERS_LOG_IN_URL = "/login";
export const USERS_REGISTER_URL = "/sign-up";
export const FORGOT_PASSWORD_URL = "/reset-password";
export const ACCOUNT_READY_URL = "/account-ready";
export const HOMEPAGE_BLOG_URL =
  process.env.NEXT_PUBLIC_HOMEPAGE_BLOG_URL || "https://politicall.com/";
export const ACTIVITY_DESCRIPTION_MAX_LENGTH = 1000;

export const MOBILE_APPSTORE_URL_FR =
  "https://apps.apple.com/fr/app/politicall-r%C3%A9seau-social/id1634020746";
export const MOBILE_APPSTORE_URL_EN =
  "https://apps.apple.com/us/app/politicall-social-media/id1634020746";
export const MOBILE_PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=com.politicall";

export const activityTypeUrl = {
  Blog: "/blogs",
  Debate: "/debates",
  Event: "/events",
  Group: "/groups",
  LiveStream: "/livestreams",
  Petition: "/petitions",
} as const;

export const activityTypePlural = {
  Blog: "Blogs",
  Debate: "Debates",
  Event: "Events",
  Group: "Groups",
  LiveStream: "LiveStreams",
  Petition: "Petitions",
};

export const typenameToResourceType = {
  Blog: ResourceType.Blog,
  Debate: ResourceType.Debate,
  Event: ResourceType.Event,
  Group: ResourceType.Group,
  Petition: ResourceType.Petition,
};
