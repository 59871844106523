import React, { forwardRef, ReactNode, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Theme, Zoom, ClickAwayListener, IconButton, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import Link, { HrefProps } from "@/common/components/Link";

export const useSnackbarStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    position: "fixed",
    bottom: "1.5rem",
    left: 0,
    right: 0,
    margin: "0 auto",
    maxWidth: 812,
    boxSizing: "border-box",
    zIndex: 99999,
    boxShadow: theme.mode.shadow.popover,
    [theme.breakpoints.down("md")]: {
      maxWidth: 664,
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "4rem",
      left: "1rem",
      right: "1rem",
      width: "auto",
    },
  },
  content: {
    backgroundColor: `${theme.mode.background.snackbar}f2`, // 95% alpha
    borderRadius: "16px",
    padding: "2.5rem 3.25rem 2.5rem 2rem",
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  message: {
    fontSize: "1.313rem",
    fontWeight: "bold",
    color: theme.mode.text.contrast,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.065rem",
    },
  },
  defaultAction: {
    backgroundColor: theme.mode.background.light,
    borderRadius: "50%",
    padding: 8,
    height: "auto",
    "&:hover": {
      backgroundColor: theme.mode.background.light,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 6,
    },
  },
  actionLink: {
    color: theme.palette.primary.main,
    fontSize: "1.065rem",
    fontWeight: "bold",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8125rem",
    },
    textAlign: "right",
    textDecoration: "none",
  },
  error: {
    backgroundColor: `${theme.mode.background.snackbarError}f2`,
  },
}));

type SnackbarCommonProps = {
  open: boolean;
  style?: "default" | "error";
  delay?: number;
  onClose?: () => void;
};

type ActionLink = {
  text: string;
  href: HrefProps;
};

export type SnackbarProps =
  | ({
      action: ReactNode;
      actionLink?: never;
      hideDefaultAction?: never;
      message: ReactNode;
      children?: never;
    } & SnackbarCommonProps)
  | ({
      action?: never;
      actionLink: ActionLink;
      hideDefaultAction?: true;
      message: ReactNode;
      children?: never;
    } & SnackbarCommonProps)
  | ({
      action?: never;
      actionLink?: never;
      hideDefaultAction?: true;
      message: ReactNode;
      children?: never;
    } & SnackbarCommonProps)
  | ({
      action?: never;
      actionLink?: never;
      hideDefaultAction?: never;
      message?: never;
      children: ReactNode;
    } & SnackbarCommonProps);

export const SnackbarActionLink = ({ text, href }: ActionLink) => {
  const { classes } = useSnackbarStyles();

  return (
    <Link href={href} passHref>
      <a className={classes.actionLink}>{text}</a>
    </Link>
  );
};

const SnackbarContent = forwardRef<
  HTMLDivElement,
  Omit<SnackbarProps, "onClose" | "open" | "delay"> & { handleClose: () => void }
>(({ message, handleClose, action, actionLink, hideDefaultAction, style = "default" }, ref) => {
  const { cx, classes } = useSnackbarStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const DefaultAction = () => (
    <IconButton classes={{ root: classes.defaultAction }} onClick={handleClose} size="large">
      <CloseIcon fontSize={isMobile ? "small" : "medium"} />
    </IconButton>
  );

  return (
    <div className={cx(classes.content, { [classes.error]: style === "error" })} ref={ref}>
      <h4 className={classes.message} data-cy="Snackbar__message">
        {message}
      </h4>
      {actionLink ? (
        <SnackbarActionLink {...actionLink} />
      ) : (
        action || hideDefaultAction || <DefaultAction />
      )}
    </div>
  );
});

SnackbarContent.displayName = "SnackbarContent";

const Snackbar = ({ open, delay = 5000, onClose, ...props }: SnackbarProps) => {
  const { classes } = useSnackbarStyles();

  const handleClose = () => {
    onClose?.call(null);
  };

  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        handleClose();
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [open]);

  return (
    <Zoom in={open} mountOnEnter unmountOnExit>
      <section className={classes.wrapper} data-cy="Snackbar">
        <ClickAwayListener onClickAway={handleClose}>
          <SnackbarContent {...props} handleClose={handleClose} />
        </ClickAwayListener>
      </section>
    </Zoom>
  );
};

export default Snackbar;
