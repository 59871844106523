import localePaths from "config/locale-paths";

import { SITE_URL } from "../constants";
import i18n from "./i18n";

export const getAlternateUrl = (path: string, targetLocale: string) => {
  const pathWithLocale = `/${targetLocale}${path}`;
  const matchingReplacePath = localePaths.find((localePath) =>
    localePath.redirectPattern.test(pathWithLocale),
  );
  if (matchingReplacePath) {
    return `${SITE_URL}${pathWithLocale.replace(
      matchingReplacePath.redirectPattern,
      matchingReplacePath.redirectReplaceString,
    )}`;
  }
  return `${SITE_URL}${pathWithLocale}`;
};

export const getLocalisedPath = (pathname: string, locale?: string) => {
  const pathnameWithLocale =
    pathname.startsWith("/en/") || pathname.startsWith("/fr/")
      ? pathname
      : `/${locale || i18n.language}${pathname}`;
  const matchingRedirect = localePaths.find((localePath) =>
    localePath.redirectPattern.test(pathnameWithLocale),
  );
  const redirectedPathname = matchingRedirect
    ? pathnameWithLocale.replace(
        matchingRedirect.redirectPattern,
        matchingRedirect.redirectReplaceString,
      )
    : pathnameWithLocale;
  return redirectedPathname;
};
