import { gql, TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

import { PostActivityBaseFragment, PostActivityBaseFragmentDoc } from "@/graphql/types";

export const Post: TypePolicy = {
  fields: {
    comments: relayStylePagination(["sortBy"]),
    url: {
      read(_, { readField, cache }) {
        let activityUrl: string | undefined;
        try {
          const activity = readField("activity");
          const activityFragmentName = (activity as any)?.__ref;
          const activityFragment = activityFragmentName
            ? (cache.readFragment({
                id: activityFragmentName,
                fragment: PostActivityBaseFragmentDoc,
              }) as PostActivityBaseFragment | undefined)
            : undefined;
          activityUrl = activityFragment?.url || "";
        } catch {}

        if (activityUrl) {
          return `${activityUrl}/posts/${readField("id")}`;
        }

        if (readField("anonymous")) {
          return `/posts/${readField("id")}`;
        }

        let authorUrl: string | undefined;
        try {
          const author = readField("author");
          const authorFragmentName = (author as any)?.__ref;
          const authorFragment = authorFragmentName
            ? (cache.readFragment({
                id: authorFragmentName,
                fragment: gql`
                  fragment PostAuthor on User {
                    id
                    username
                    slug
                    url @client
                  }
                `,
              }) as { url: string } | undefined)
            : undefined;
          authorUrl = authorFragment?.url || "";
        } catch {}

        if (authorUrl) {
          return `${authorUrl}/posts/${readField("id")}`;
        }

        return `/posts/${readField("id")}`;
      },
    },
  },
};
