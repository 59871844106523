import React, { createContext, useState, ComponentProps, FC } from "react";

import Cookies from "js-cookie";

import { COOKIE_CONSENT_KEY, EMPTY_CONSENT } from "@/common/constants/cookieConsent";
import { CookieConsent } from "@/common/models/CookieConsentTypes";

interface ConsentContextInterface {
  consent: CookieConsent;
  setConsent: (data: CookieConsent) => void;
}

export const ConsentContext = createContext<ConsentContextInterface>({} as ConsentContextInterface);

export const ConsentProvider = ({ children }: ComponentProps<FC>) => {
  const [consent, setConsent] = useState<CookieConsent>(
    Cookies.getJSON(COOKIE_CONSENT_KEY) || EMPTY_CONSENT,
  );

  return (
    <ConsentContext.Provider value={{ consent, setConsent }}>{children}</ConsentContext.Provider>
  );
};

export default ConsentContext;
