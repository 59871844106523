// All environment variables to be used throught the application
export const SITE_URL = process.env.NEXT_PUBLIC_FRONTEND_URL || "https://politicall.com";
export const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || "https://backend.politicall.com";
export const GRAPHQL_URL =
  process.env.NEXT_PUBLIC_GRAPHQL_API_URL || "https://development-backend.politicall.com/graphql";
export const WS_URL =
  process.env.NEXT_PUBLIC_WS_URL || "ws://development-backend.politicall.com/socket";
export const CDN_HOST = process.env.NEXT_PUBLIC_CDN_HOST;
export const MUX_ENV_KEY = process.env.NEXT_PUBLIC_MUX_ENV_KEY;
export const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV || "DEV";
export const CAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY;
export const ONESIGNAL_APP_ID = process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID || "";
export const ONESIGNAL_SAFARI_ID = process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_ID || "";
