import { useSubscription } from "@apollo/client";

import {
  PetitionFragment,
  GetPetitionPostsQuery,
  GetPetitionPostsQueryVariables,
  GetPetitionPostsDocument,
  PostInput,
  PostsOrder,
  PetitionAddedPostsCountDocument,
  useGetPetitionPostsLazyQuery,
  useCreatePetitionPostMutation,
} from "@/graphql/types";
import { PostsOrderOption } from "@/posts/models/PostsOrderOption";

export const usePetitionsPostsStore = (petition: PetitionFragment) => {
  const [fetchPosts, { data: postsData, networkStatus, fetchMore: fetchNextPostsPage, refetch }] =
    useGetPetitionPostsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    });
  const { data: unreadPostsCountData } = useSubscription(PetitionAddedPostsCountDocument, {
    variables: { id: petition.id, lastSeenPostId: postsData?.petition?.posts.edges[0]?.node.id },
    skip: networkStatus === 1,
  });
  const [createPost] = useCreatePetitionPostMutation();

  return {
    posts: postsData?.petition?.posts.edges.map(({ node }) => node) || [],
    unreadPostsCount: unreadPostsCountData?.petitionAddedPostsCount || 0,
    loading: networkStatus === 1,
    loadingRefetch: networkStatus === 4,
    hasMorePages: postsData?.petition?.posts.pageInfo.hasNextPage || false,
    fetchPosts({ order }: { order?: PostsOrderOption }) {
      return fetchPosts({
        variables: {
          id: petition.id,
          sortBy:
            order === PostsOrderOption.MostRecent ? PostsOrder.NewestFirst : PostsOrder.OldestFirst,
        },
      });
    },
    refetch() {
      return refetch && refetch();
    },
    createPost(data: PostInput) {
      return createPost({
        variables: {
          petitionId: petition.id,
          data,
        },
        update: (cache, { data }) => {
          if (!data?.createPetitionPost?.result) return;
          const result = cache.readQuery<GetPetitionPostsQuery>({
            query: GetPetitionPostsDocument,
            variables: {
              id: petition.id,
              sortBy: PostsOrder.NewestFirst,
            },
          });
          if (result && result.petition) {
            const newData: GetPetitionPostsQuery = {
              ...result,
              petition: {
                ...result.petition,
                posts: {
                  ...result.petition.posts,
                  edges: [{ node: data.createPetitionPost.result }, ...result.petition.posts.edges],
                },
              },
            };
            cache.writeQuery<GetPetitionPostsQuery, GetPetitionPostsQueryVariables>({
              query: GetPetitionPostsDocument,
              variables: {
                id: petition.id,
                sortBy: PostsOrder.NewestFirst,
              },
              data: newData,
            });
          }
        },
      });
    },
    fetchNextPostsPage() {
      if (fetchNextPostsPage && postsData?.petition) {
        return fetchNextPostsPage({
          variables: {
            after: postsData.petition.posts.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
