import { GlobalStyles as MuiGlobalStyles } from "@mui/material";

export const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={(theme) => ({
      "*": {
        lineHeight: "normal",
      },

      body: {
        margin: 0,
        padding: 0,
        fontFamily: `
          "Open Sans",
          -apple-system,
          BlinkMacSystemFont,
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          sans-serif`,
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        fontSize: "0.875rem",
        lineHeight: "1.15rem",
      },

      "html, body": {
        backgroundColor: theme.mode.background.default,
        minHeight: "calc(var(--vh, 1vh) * 100)",
      },

      a: {
        textDecoration: "none",
        color: "inherit",
      },

      "input[disabled]": {
        pointerEvents: "none",
      },

      // quill-mention
      ".ql-mention-list-container": {
        borderColor: theme.mode.background.menu.default,
        borderWidth: "2px",
        backgroundColor: theme.mode.background.menu.default,
      },
      ".ql-mention-list": {
        backgroundColor: theme.mode.background.menu.default,
      },
      ".ql-mention-list .ql-mention-list-item.selected": {
        backgroundColor: theme.mode.background.menu.hover,
      },
      ".mention": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        fontWeight: 700,
        margin: 0,

        "& > span": {
          margin: 0,
        },
      },

      // quill
      ".ql-stroke, .ql-fill": {
        stroke: `${theme.mode.icon.quill} !important`,
      },
      ".ql-picker-label": {
        color: `${theme.mode.icon.quill} !important`,
      },

      // MUI
      ".MuiAutocomplete-noOptions": {
        color: `${theme.mode.text.heading} !important`,
      },
    })}
  />
);
