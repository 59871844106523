import React, { createContext, ReactNode, useContext } from "react";

import { FeatureFlag, useFeatureFlagsQuery } from "@/graphql/types";

import { FeatureFlagName, FEATURE_FLAGS } from "../constants/flags";

const FlagsContext = createContext({
  flags: [] as FeatureFlag[],
  loading: false,
});

export const FlagsProvider = ({
  children,
  names = FEATURE_FLAGS,
}: {
  children: ReactNode;
  names: readonly FeatureFlagName[];
}) => {
  const { data, loading } = useFeatureFlagsQuery({ variables: { names: names as string[] } });

  return (
    <FlagsContext.Provider value={{ flags: data?.featureFlags || [], loading }}>
      {children}
    </FlagsContext.Provider>
  );
};

export const useFlags = () => useContext(FlagsContext);

export const useFlag = (name: FeatureFlagName) => {
  const { flags, loading } = useFlags();

  return { enabled: flags.find((flag) => flag.name === name)?.enabled || false, loading };
};
