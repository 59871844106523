export const TOUR_APPEARANCE_DELAY = 1500;

interface TourTopicsMapProps {
  [key: string]: {
    topic: string;
    subtopic?: string;
    waitless: boolean;
  };
}

export const TOUR_TOPICS_MAP: TourTopicsMapProps = {
  "/": {
    topic: "take-a-tour-discover",
    waitless: true,
  },
  "/community": {
    topic: "take-a-tour-community",
    waitless: false,
  },
  "/groups": {
    topic: "take-a-tour-activities",
    subtopic: "groups",
    waitless: true,
  },
  "/debates": {
    topic: "take-a-tour-activities",
    subtopic: "debates",
    waitless: true,
  },
  "/events": {
    topic: "take-a-tour-activities",
    subtopic: "events",
    waitless: true,
  },
  "/blogs": {
    topic: "take-a-tour-activities",
    subtopic: "blogs",
    waitless: true,
  },
  "/petitions": {
    topic: "take-a-tour-activities",
    subtopic: "petitions",
    waitless: true,
  },
  "/settings/account": {
    topic: "take-a-tour-settings",
    waitless: true,
  },
};
