import React, { isValidElement, cloneElement, Dispatch, SetStateAction } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { StepType } from "@reactour/tour";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme: Theme) => ({
  closeIcon: {
    fontSize: "1.25rem",
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  hintContent: {
    color: theme.mode.text.contrast,
  },
  title: {
    fontSize: "1.3125rem",
    fontWeight: 800,
    lineHeight: "normal",
    paddingRight: "1.25rem",
    paddingBottom: theme.spacing(1.5),
  },
  body: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "normal",
  },
  hintFooter: {
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  steps: {
    fontSize: "0.8125rem",
    fontWeight: 800,
    color: theme.palette.primary.main,
  },
  action: {
    fontSize: "1.0625rem",
    fontWeight: 800,
    textTransform: "none",
    padding: 0,
    lineHeight: "normal",
  },
}));

export interface TourHintProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  steps: StepType[];
  onClose: () => void;
}

const TourHint = ({ currentStep, steps, setIsOpen, setCurrentStep, onClose }: TourHintProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { content } = steps[currentStep];
  const isLastStep = currentStep === steps.length - 1;

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleNextClick = async () => {
    if (isLastStep) {
      handleClose();
    } else {
      setCurrentStep((step) => step + 1);
    }
  };

  return (
    <>
      <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      <div className={classes.hintContent}>
        {isValidElement(content)
          ? cloneElement(content, { classes: { title: classes.title, body: classes.body } })
          : content}
      </div>
      <div className={classes.hintFooter}>
        <div className={classes.steps}>
          {t("Generic.Range", { given: currentStep + 1, total: steps.length })}
        </div>
        <Button
          color="primary"
          size="small"
          disableRipple
          className={classes.action}
          onClick={handleNextClick}>
          {isLastStep ? t("Tour.Actions.GotIt") : t("Tour.Actions.Next")}
        </Button>
      </div>
    </>
  );
};

export default TourHint;
