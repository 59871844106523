import { useSubscription } from "@apollo/client";

import {
  EventFragment,
  GetEventPostsQuery,
  GetEventPostsQueryVariables,
  GetEventPostsDocument,
  PostInput,
  PostsOrder,
  EventAddedPostsCountDocument,
  useGetEventPostsLazyQuery,
  useCreateEventPostMutation,
} from "@/graphql/types";
import { PostsOrderOption } from "@/posts/models/PostsOrderOption";

export const useEventsPostsStore = (event: EventFragment) => {
  const [fetchPosts, { data: postsData, networkStatus, fetchMore: fetchNextPostsPage, refetch }] =
    useGetEventPostsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    });
  const { data: unreadPostsCountData } = useSubscription(EventAddedPostsCountDocument, {
    variables: { id: event.id, lastSeenPostId: postsData?.event?.posts.edges[0]?.node.id },
    skip: networkStatus === 1,
  });
  const [createPost] = useCreateEventPostMutation();

  return {
    posts: postsData?.event?.posts.edges.map(({ node }) => node) || [],
    unreadPostsCount: unreadPostsCountData?.eventAddedPostsCount || 0,
    hasMorePages: postsData?.event?.posts.pageInfo.hasNextPage || false,
    loading: networkStatus === 1,
    loadingRefetch: networkStatus === 4,
    fetchPosts({ order }: { order?: PostsOrderOption }) {
      return fetchPosts({
        variables: {
          id: event.id,
          sortBy:
            order === PostsOrderOption.MostRecent ? PostsOrder.NewestFirst : PostsOrder.OldestFirst,
        },
      });
    },
    refetch() {
      return refetch && refetch();
    },
    createPost(data: PostInput) {
      return createPost({
        variables: {
          eventId: event.id,
          data,
        },
        update: (cache, { data }) => {
          if (!data?.createEventPost?.result) return;
          const result = cache.readQuery<GetEventPostsQuery>({
            query: GetEventPostsDocument,
            variables: {
              id: event.id,
              sortBy: PostsOrder.NewestFirst,
            },
          });

          if (result && result.event) {
            const newData: GetEventPostsQuery = {
              ...result,
              event: {
                ...result.event,
                posts: {
                  ...result.event.posts,
                  edges: [{ node: data.createEventPost.result }, ...result.event.posts.edges],
                },
              },
            };
            cache.writeQuery<GetEventPostsQuery, GetEventPostsQueryVariables>({
              query: GetEventPostsDocument,
              variables: {
                id: event.id,
                sortBy: PostsOrder.NewestFirst,
              },
              data: newData,
            });
          }
        },
      });
    },
    fetchNextPostsPage() {
      if (fetchNextPostsPage && postsData?.event) {
        return fetchNextPostsPage({
          variables: {
            after: postsData.event.posts.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
