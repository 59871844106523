export const palettes: any = {
  red: {
    primary: {
      main: "#ff5c5c",
      dark: "#ff3333",
      light: "#f54b23",
      contrastText: "#fff",
    },
  },
  blue: {
    primary: {
      main: "#5c81ff",
      dark: "#3363ff",
      light: "#54c2fc",
      contrastText: "#fff",
    },
  },
  pink: {
    primary: {
      main: "#ff70d2", // old: "#ff70cd",
      dark: "#ff33b8",
      light: "#f795b7",
      contrastText: "#fff",
    },
  },
  orange: {
    primary: {
      main: "#f98012",
      dark: "#ff6900",
      light: "#f8bd1c",
      contrastText: "#fff",
    },
  },
  yellow: {
    primary: {
      main: "#ffbb00", // old: #ffed5c
      dark: "#f5d800",
      light: "#f8f08b",
      contrastText: "#fff",
    },
  },
  green: {
    primary: {
      main: "#36c62f", // old: "#83e17e",
      dark: "#50d449",
      light: "#b8e986",
      contrastText: "#fff",
    },
  },
  purple: {
    primary: {
      main: "#a15cff",
      dark: "#7c1fff",
      light: "#a15cff",
      contrastText: "#fff",
    },
  },
};
