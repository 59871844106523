import {
  PetitionCardFragment,
  PetitionFragment,
  PetitionCardSlimFragment,
  useReportPetitionMutation,
} from "@/graphql/types";

export const usePetitionsReportsStore = (
  petition: PetitionFragment | PetitionCardFragment | PetitionCardSlimFragment,
) => {
  const [report] = useReportPetitionMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: petition.id,
            type,
            comment,
          },
        }).then(({ data }) => {
          if (data?.reportPetition?.successful) {
            resolve(data?.reportPetition?.result);
          } else {
            reject(data?.reportPetition);
          }
        });
      });
    },
  };
};
