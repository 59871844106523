import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const listBlockedUsers: TypePolicy = relayStylePagination();

export const BlockedUser: TypePolicy = {
  fields: {
    displayName: {
      read(_, { readField }) {
        const fullName = readField<string>("fullName");
        const username = readField<string>("username");
        const showRealName = readField<boolean>("showRealName");

        if (showRealName) {
          return fullName;
        }
        return username;
      },
    },
  },
};
