import { createTheme, Theme } from "@mui/material/styles";

import { colorModes, palettes } from "@/common/constants";
import { ThemeMode } from "@/graphql/types";

export const createThemeObject = (colorName: string, mode: ThemeMode) => {
  // First, defining most basic options
  let theme = createTheme({
    mode: {
      ...(mode === ThemeMode.Light ? colorModes.light : colorModes.dark),
    },
    palette: {
      ...palettes[colorName],
    },
    typography: {
      fontFamily: [
        "Open Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ].join(","),
      h1: {},
    },
  });

  const primaryColor =
    mode === ThemeMode.Light ? colorModes.light.text.primary : colorModes.dark.text.primary;
  const secondaryColor =
    mode === ThemeMode.Light ? colorModes.light.text.primary : colorModes.dark.text.primary;

  // Typography
  theme = createTheme(theme, {
    typography: {
      h1: {
        ...theme.typography.h1,
        fontSize: "2.6rem",
        lineHeight: "3.5rem",
        fontWeight: 700,
        [theme.breakpoints.up("sm")]: {
          fontSize: "3rem",
          lineHeight: "4.1rem",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "4rem",
          lineHeight: "5.2rem",
        },
        color: primaryColor,
      },
      h2: {
        ...theme.typography.h2,
        fontSize: "1.6rem",
        lineHeight: "2rem",
        fontWeight: 700,
        [theme.breakpoints.up("sm")]: {
          fontSize: "2.3rem",
          lineHeight: "3rem",
        },
        color: primaryColor,
      },
      h3: {
        ...theme.typography.h3,
        fontSize: "1.3rem",
        lineHeight: "2rem",
        fontWeight: 700,
        [theme.breakpoints.up("sm")]: {
          fontSize: "1.5rem",
          lineHeight: "2.3rem",
        },
        color: primaryColor,
      },
      h4: {
        ...theme.typography.h4,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1.25rem",
          lineHeight: "1.75rem",
        },
        color: primaryColor,
      },
      h5: {
        ...theme.typography.h5,
        fontSize: "1rem",
        lineHeight: "1.6rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1.1rem",
          lineHeight: "1.7rem",
        },
        color: primaryColor,
      },
      h6: {
        ...theme.typography.h6,
        fontWeight: 700,
        fontSize: "0.8rem",
        lineHeight: "1.15rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        },
        color: primaryColor,
      },
      body1: {
        ...theme.typography.body1,
        fontSize: "0.875rem",
        lineHeight: "1.15rem",
        color: secondaryColor,
      },
      body2: {
        ...theme.typography.body2,
        fontSize: "0.7rem",
        color: secondaryColor,
      },
      subtitle1: {
        ...theme.typography.subtitle1,
        fontSize: "0.75rem",
        color: secondaryColor,
      },
      subtitle2: {
        ...theme.typography.subtitle2,
        fontSize: "0.75rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "0.875rem",
        },
        color: secondaryColor,
      },
      caption: {
        ...theme.typography.caption,
        fontSize: "0.65rem",
        fontWeight: 600,
        color: secondaryColor,
      },
    },
  } as Partial<Theme>);

  // Overrides

  const dialogSpacings = {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  };

  theme = createTheme(theme, {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: theme.mode.background.light,
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: theme.mode.text.description,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: theme.mode.icon.default,
          },
          fontSizeSmall: {
            fontSize: "1rem",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: theme.mode.icon.default,
            padding: theme.spacing(1),
            "&:hover": {
              backgroundColor: "transparent",
              opacity: 0.8,
            },
            height: "2em",
            [theme.breakpoints.down("sm")]: {
              height: "1.3em",
            },
          },
          colorPrimary: {
            "&:hover": {
              backgroundColor: "transparent",
              opacity: 0.8,
            },
          },
          sizeSmall: {
            ...theme.typography.h6,
            fontWeight: "normal",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontSize: "1.25rem",
            paddingTop: 12,
            paddingBottom: 0,
            minHeight: 40,
            [theme.breakpoints.down("sm")]: {
              fontSize: "0.8rem",
            },
            "&.Mui-selected": {
              fontWeight: "bold",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 40,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          },
          input: {
            color: theme.mode.text.heading,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: theme.mode.background.input,
          },
          input: {
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 20,
            color: theme.mode.text.heading,
          },
          inputMarginDense: {
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 24,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            fontSize: theme.typography.h5.fontSize,
          },
          button: {
            fill: theme.palette.grey.A700,
            backgroundColor: theme.mode.background.light,
            "&:hover": {
              backgroundColor: theme.mode.background.default,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ...theme.typography.h5,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            fontWeight: 600,
            backgroundColor: theme.mode.background.menu.default,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: 16,
            backgroundColor: theme.mode.background.menu.default,
          },
          list: {
            backgroundColor: theme.mode.background.menu.default,
            "& .Mui-selected": {
              backgroundColor: theme.mode.background.menu.default + " !important",
              color: theme.palette.primary.main,
            },
            "& .MuiListItem-button:hover": {
              backgroundColor: theme.mode.background.menu.hover,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: "0rem",
            paddingRight: "0rem",
            paddingBottom: "0rem",
            paddingLeft: "0rem",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            ...theme.typography.h6,
            fontWeight: 400,
            padding: theme.spacing(1),
            backgroundColor: theme.mode.tooltip.background,
            color: theme.mode.tooltip.text,
          },
          arrow: {
            color: theme.mode.tooltip.background,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
          button: {
            "&:disabled": {
              opacity: 0.4,
              cursor: "default",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            transform: "translateY(10px)",
          },
          paper: {
            boxShadow: theme.mode.shadow.popover,
            backgroundColor: theme.mode.background.popover,
            borderRadius: "16px",
            transform: "translateY(10px)",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              left: "16px",
            },
            "& .MuiListItem-button": {
              backgroundColor: theme.mode.background.menu.default,
              "&:hover": {
                backgroundColor: theme.mode.background.menu.hover,
              },
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: theme.mode.text.heading,
            fontSize: "1.065rem",
            fontWeight: 600,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0,0,0,0) !important",
            fontWeight: 800,
            color: theme.palette.primary.main,
            border: "none",
          },
          icon: {
            color: theme.palette.primary.main,
            fill: theme.palette.primary.main,
            top: "auto",
          },
          select: {
            color: theme.palette.primary.main,
            border: "none",
            backgroundColor: "rgba(0,0,0,0) !important",
            fontWeight: 800,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "1.5rem",
          },
        },
        defaultProps: {
          elevation: 3,
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 24,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: theme.spacing(2, 3, 5, 3),
            ...dialogSpacings,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            ...dialogSpacings,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            ...dialogSpacings,
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            color: theme.palette.common.white,
            fontWeight: 800,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            zIndex: 1402,
          },
        },
      },
    },
    shadows: {
      ...theme.shadows,
      3: theme.mode.shadow.smallCard,
    },
  } as Partial<Theme>);

  return theme;
};

export const getThemeColors = () => {
  return Object.keys(palettes);
};

export const getThemes = () => {
  return Object.keys(colorModes).map((colorMode) => {
    switch (colorMode) {
      case "light":
        return ThemeMode.Light;
      case "dark":
        return ThemeMode.Dark;
      default:
        break;
    }
  });
};
