import { CSSProperties } from "react";

import upperFirst from "lodash/upperFirst";

export interface DoArrowStylesProps {
  position?: string;
  verticalAlign: string;
  horizontalAlign: string;
  isMobile: boolean;
  color: string;
}

export interface HintProps {
  step: number;
  name: string;
}

export interface ConvertHintsToStepsProps {
  hints: HintProps[];
  topic: string;
  subtopic?: string;
}

export const doArrowStyles = ({
  position,
  verticalAlign,
  horizontalAlign,
  isMobile,
  color,
}: DoArrowStylesProps) => {
  if (!position || position === "custom") {
    return {};
  }

  const oppositeSide: { [key: string]: string } = {
    top: "bottom",
    bottom: "top",
    right: "left",
    left: "right",
  };
  const width = 16;
  const height = 16;
  const isVertical = position === "top" || position === "bottom";

  let containerStyles: CSSProperties = {};
  let arrowStyles: CSSProperties = {};
  const opositHorizontal = oppositeSide[horizontalAlign];

  if (isMobile) {
    arrowStyles = {
      [isVertical ? "borderLeft" : "borderTop"]: `${width / 2}px solid transparent`,
      [isVertical ? "borderRight" : "borderBottom"]: `${width / 2}px solid transparent`,
      [`border${position[0].toUpperCase()}${position.substring(1)}`]: `${height}px solid ${color}`,
      [isVertical ? oppositeSide[horizontalAlign] : verticalAlign]: height * 2,
      [oppositeSide[position]]: -height,
    };
  } else {
    containerStyles = {
      [`border${upperFirst(verticalAlign)}${upperFirst(opositHorizontal)}Radius`]: 0,
    };
    arrowStyles = {
      backgroundColor: color,
      borderTopLeftRadius: 4,
      transform: isVertical
        ? `skewY(${horizontalAlign === "right" ? 45 : -45}deg) translateY(50%)`
        : `skewX(${verticalAlign === "top" ? 45 : -45}deg) translate(50%)`,
      [isVertical ? oppositeSide[horizontalAlign] : verticalAlign]: 0,
      [oppositeSide[position]]: -height,
    };
  }

  return {
    ...containerStyles,
    "&::after": {
      content: "''",
      width,
      height,
      position: "absolute",
      ...arrowStyles,
    },
  };
};

export const convertHintToSteps = ({ hints = [], topic, subtopic }: ConvertHintsToStepsProps) =>
  [...hints]
    .sort((a, b) => a.step - b.step)
    .map(({ step }) => ({
      selector: `[data-tour='${topic}-step-${step}']`,
      title: [`Tour.${topic}.${subtopic}.Step${step}.Title`, `Tour.${topic}.Step${step}.Title`], // second argument used as a fallback
      body: [`Tour.${topic}.${subtopic}.Step${step}.Body`, `Tour.${topic}.Step${step}.Body`],
    }));
