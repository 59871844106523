import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type ButtonStylesProps = { uppercase?: boolean };

export const useStyles = makeStyles<ButtonStylesProps>()((theme: Theme, { uppercase }) => ({
  button: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    border: "none",
    boxSizing: "border-box",
    textTransform: uppercase ? "uppercase" : undefined,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    cursor: "pointer",
    textDecoration: "none",
    "&:disabled": {
      cursor: "not-allowed",
    },
    "& span": {
      width: "100%",
    },
  },
  primary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    transition: "background-color 80ms linear",
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.05),
    },
    "&:disabled": {
      color: "#a9a9b2",
      backgroundColor: "#e4e4e4",
    },
  },
  secondary: {
    color: theme.mode.text.description,
    backgroundColor: theme.mode.icon.backgroundSecondary,
    transition: "background-color 80ms linear",
    "&:hover": {
      backgroundColor: darken(theme.mode.icon.backgroundSecondary, 0.03),
    },
  },
  "inline-primary": {
    color: theme.palette.primary.main,
    backgroundColor: "unset",
  },
  "inline-secondary": {
    color: theme.mode.text.description,
    backgroundColor: "unset",
  },
  tertiary: {
    color: theme.palette.grey["200"],
    backgroundColor: theme.palette.grey["600"],
  },
  text: {
    color: theme.palette.primary.main,
    backgroundColor: "unset",
  },
  small: {
    padding: theme.spacing(1, 1.5),
    borderRadius: 16,
    height: 32,
    fontSize: "0.6875rem",
    fontWeight: 700,
  },
  medium: {
    padding: theme.spacing(1.75, 2),
    borderRadius: "999px",
    minHeight: "48px",
    fontSize: "1.1rem",
  },
  formless: {
    color: "unset",
    backgroundColor: "unset",
    padding: "unset",
    fontSize: "unset",
    minHeight: "unset",
    borderRadius: "unset",
    display: "unset",
    "& span": {
      width: "unset",
    },
  },
}));
