import { Blog } from "./blog";
import { blogs } from "./blogs";
import { followedBlogs } from "./followed-blogs";

export const blogQueryFields = {
  blogs,
  followedBlogs,
};

export { Blog };
