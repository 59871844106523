module.exports = [
  {
    rewritePattern: /^\/fr\/charte-dutilisation/,
    rewriteReplaceString: "/fr/code-of-conduct",

    redirectPattern: /^\/fr\/code-of-conduct/,
    redirectReplaceString: "/fr/charte-dutilisation",
  },
  {
    rewritePattern: /^\/en\/charte-dutilisation/,
    rewriteReplaceString: "/en/code-of-conduct",

    redirectPattern: /^\/en\/charte-dutilisation/,
    redirectReplaceString: "/en/code-of-conduct",
  },
  {
    rewritePattern: /^\/fr\/inscrivez-vous/,
    rewriteReplaceString: "/fr/sign-up",

    redirectPattern: /^\/fr\/sign-up/,
    redirectReplaceString: "/fr/inscrivez-vous",
  },
  {
    rewritePattern: /^\/en\/inscrivez-vous/,
    rewriteReplaceString: "/en/sign-up",

    redirectPattern: /^\/en\/inscrivez-vous/,
    redirectReplaceString: "/en/sign-up",
  },
  {
    rewritePattern: /^\/fr\/reinitialiser-le-mot-de-passe/,
    rewriteReplaceString: "/fr/reset-password",

    redirectPattern: /^\/fr\/reset-password/,
    redirectReplaceString: "/fr/reinitialiser-le-mot-de-passe",
  },
  {
    rewritePattern: /^\/en\/reinitialiser-le-mot-de-passe/,
    rewriteReplaceString: "/en/reset-password",

    redirectPattern: /^\/en\/reinitialiser-le-mot-de-passe/,
    redirectReplaceString: "/en/reset-password",
  },
  {
    rewritePattern: /^\/fr\/politique-de-confidentialite/,
    rewriteReplaceString: "/fr/privacy-policy",

    redirectPattern: /^\/fr\/privacy-policy/,
    redirectReplaceString: "/fr/politique-de-confidentialite",
  },
  {
    rewritePattern: /^\/en\/politique-de-confidentialite/,
    rewriteReplaceString: "/en/privacy-policy",

    redirectPattern: /^\/en\/politique-de-confidentialite/,
    redirectReplaceString: "/en/privacy-policy",
  },
  {
    rewritePattern: /^\/fr\/mentions-legales/,
    rewriteReplaceString: "/fr/terms-and-conditions",

    redirectPattern: /^\/fr\/terms-and-conditions/,
    redirectReplaceString: "/fr/mentions-legales",
  },
  {
    rewritePattern: /^\/en\/mentions-legales/,
    rewriteReplaceString: "/en/terms-and-conditions",

    redirectPattern: /^\/en\/mentions-legales/,
    redirectReplaceString: "/fr/terms-and-conditions",
  },
  {
    rewritePattern: /^\/fr\/connexion/,
    rewriteReplaceString: "/fr/login",

    redirectPattern: /^\/fr\/login/,
    redirectReplaceString: "/fr/connexion",
  },
  {
    rewritePattern: /^\/en\/connexion/,
    rewriteReplaceString: "/en/login",

    redirectPattern: /^\/en\/connexion/,
    redirectReplaceString: "/en/login",
  },
  {
    rewritePattern: /^\/fr\/inviter-ses-amis/,
    rewriteReplaceString: "/fr/invite-friends",

    redirectPattern: /^\/fr\/invite-friends/,
    redirectReplaceString: "/fr/inviter-ses-amis",
  },
  {
    rewritePattern: /^\/en\/inviter-ses-amis/,
    rewriteReplaceString: "/en/invite-friends",

    redirectPattern: /^\/en\/inviter-ses-amis/,
    redirectReplaceString: "/en/invite-friends",
  },
  {
    rewritePattern: /^\/fr\/groupes\/(.*)\/modifier/,
    rewriteReplaceString: "/fr/groups/$1/edit",

    redirectPattern: /^\/fr\/groups\/(.*)\/edit/,
    redirectReplaceString: "/fr/groupes/$1/modifier",
  },
  {
    rewritePattern: /^\/en\/groupes\/(.*)\/modifier/,
    rewriteReplaceString: "/en/groups/$1/edit",

    redirectPattern: /^\/en\/groupes\/(.*)\/modifier/,
    redirectReplaceString: "/en/groups/$1/edit",
  },
  {
    rewritePattern: /^\/fr\/groupes\/creer/,
    rewriteReplaceString: "/fr/groups/create",

    redirectPattern: /^\/fr\/groups\/create/,
    redirectReplaceString: "/fr/groupes/creer",
  },
  {
    rewritePattern: /^\/en\/groupes\/creer/,
    rewriteReplaceString: "/en/groups/create",

    redirectPattern: /^\/en\/groupes\/creer/,
    redirectReplaceString: "/en/groups/create",
  },
  {
    rewritePattern: /^\/fr\/groupes\/decouvrir/,
    rewriteReplaceString: "/fr/groups/discover",

    redirectPattern: /^\/fr\/groups\/discover/,
    redirectReplaceString: "/fr/groupes/decouvrir",
  },
  {
    rewritePattern: /^\/en\/groupes\/decouvrir/,
    rewriteReplaceString: "/en/groups/discover",

    redirectPattern: /^\/en\/groupes\/decouvrir/,
    redirectReplaceString: "/en/groups/discover",
  },
  {
    rewritePattern: /^\/fr\/groupes/,
    rewriteReplaceString: "/fr/groups",

    redirectPattern: /^\/fr\/groups/,
    redirectReplaceString: "/fr/groupes",
  },
  {
    rewritePattern: /^\/en\/groupes/,
    rewriteReplaceString: "/en/groups",

    redirectPattern: /^\/en\/groupes/,
    redirectReplaceString: "/en/groups",
  },
  {
    rewritePattern: /^\/fr\/groupes\/(.*)/,
    rewriteReplaceString: "/fr/groups/$1",

    redirectPattern: /^\/fr\/groups\/(.*)/,
    redirectReplaceString: "/fr/groupes/$1",
  },
  {
    rewritePattern: /^\/en\/groupes\/(.*)/,
    rewriteReplaceString: "/en/groups/$1",

    redirectPattern: /^\/en\/groupes\/(.*)/,
    redirectReplaceString: "/en/groups/$1",
  },
  {
    rewritePattern: /^\/fr\/debats\/(.*)\/modifier/,
    rewriteReplaceString: "/fr/debates/$1/edit",

    redirectPattern: /^\/fr\/debates\/(.*)\/edit/,
    redirectReplaceString: "/fr/debats/$1/modifier",
  },
  {
    rewritePattern: /^\/en\/debats\/(.*)\/modifier/,
    rewriteReplaceString: "/en/debates/$1/edit",

    redirectPattern: /^\/en\/debats\/(.*)\/modifier/,
    redirectReplaceString: "/en/debates/$1/edit",
  },
  {
    rewritePattern: /^\/fr\/debats\/creer/,
    rewriteReplaceString: "/fr/debates/create",

    redirectPattern: /^\/fr\/debates\/create/,
    redirectReplaceString: "/fr/debats/creer",
  },
  {
    rewritePattern: /^\/en\/debats\/creer/,
    rewriteReplaceString: "/en/debates/create",

    redirectPattern: /^\/en\/debats\/creer/,
    redirectReplaceString: "/en/debates/create",
  },
  {
    rewritePattern: /^\/fr\/debats\/decouvrir/,
    rewriteReplaceString: "/fr/debates/discover",

    redirectPattern: /^\/fr\/debates\/discover/,
    redirectReplaceString: "/fr/debats/decouvrir",
  },
  {
    rewritePattern: /^\/en\/debats\/decouvrir/,
    rewriteReplaceString: "/en/debates/discover",

    redirectPattern: /^\/en\/debats\/decouvrir/,
    redirectReplaceString: "/en/debates/discover",
  },
  {
    rewritePattern: /^\/fr\/debats/,
    rewriteReplaceString: "/fr/debates",

    redirectPattern: /^\/fr\/debates/,
    redirectReplaceString: "/fr/debats",
  },
  {
    rewritePattern: /^\/en\/debats/,
    rewriteReplaceString: "/en/debates",

    redirectPattern: /^\/en\/debats/,
    redirectReplaceString: "/en/debates",
  },
  {
    rewritePattern: /^\/fr\/debats\/(.*)/,
    rewriteReplaceString: "/fr/debates/$1",

    redirectPattern: /^\/fr\/debates\/(.*)/,
    redirectReplaceString: "/fr/debats/$1",
  },
  {
    rewritePattern: /^\/en\/debats\/(.*)/,
    rewriteReplaceString: "/en/debates/$1",

    redirectPattern: /^\/en\/debats\/(.*)/,
    redirectReplaceString: "/en/debates/$1",
  },
  {
    rewritePattern: /^\/fr\/evenements\/(.*)\/modifier/,
    rewriteReplaceString: "/fr/events/$1/edit",

    redirectPattern: /^\/fr\/events\/(.*)\/edit/,
    redirectReplaceString: "/fr/evenements/$1/modifier",
  },
  {
    rewritePattern: /^\/en\/evenements\/(.*)\/modifier/,
    rewriteReplaceString: "/en/events/$1/edit",

    redirectPattern: /^\/en\/evenements\/(.*)\/modifier/,
    redirectReplaceString: "/en/events/$1/edit",
  },
  {
    rewritePattern: /^\/fr\/evenements\/creer/,
    rewriteReplaceString: "/fr/events/create",

    redirectPattern: /^\/fr\/events\/create/,
    redirectReplaceString: "/fr/evenements/creer",
  },
  {
    rewritePattern: /^\/en\/evenements\/creer/,
    rewriteReplaceString: "/en/events/create",

    redirectPattern: /^\/en\/evenements\/creer/,
    redirectReplaceString: "/en/events/create",
  },
  {
    rewritePattern: /^\/fr\/evenements\/decouvrir/,
    rewriteReplaceString: "/fr/events/discover",

    redirectPattern: /^\/fr\/events\/discover/,
    redirectReplaceString: "/fr/evenements/decouvrir",
  },
  {
    rewritePattern: /^\/en\/evenements\/decouvrir/,
    rewriteReplaceString: "/en/events/discover",

    redirectPattern: /^\/en\/evenements\/decouvrir/,
    redirectReplaceString: "/en/events/discover",
  },
  {
    rewritePattern: /^\/fr\/evenements/,
    rewriteReplaceString: "/fr/events",

    redirectPattern: /^\/fr\/events/,
    redirectReplaceString: "/fr/evenements",
  },
  {
    rewritePattern: /^\/en\/evenements/,
    rewriteReplaceString: "/en/events",

    redirectPattern: /^\/en\/evenements/,
    redirectReplaceString: "/en/events",
  },
  {
    rewritePattern: /^\/fr\/evenements\/(.*)/,
    rewriteReplaceString: "/fr/events/$1",

    redirectPattern: /^\/fr\/events\/(.*)/,
    redirectReplaceString: "/fr/evenements/$1",
  },
  {
    rewritePattern: /^\/en\/evenements\/(.*)/,
    rewriteReplaceString: "/en/events/$1",

    redirectPattern: /^\/en\/evenements\/(.*)/,
    redirectReplaceString: "/en/events/$1",
  },
  {
    rewritePattern: /^\/fr\/blogs\/(.*)\/modifier/,
    rewriteReplaceString: "/fr/blogs/$1/edit",

    redirectPattern: /^\/fr\/blogs\/(.*)\/edit/,
    redirectReplaceString: "/fr/blogs/$1/modifier",
  },
  {
    rewritePattern: /^\/en\/blogs\/(.*)\/modifier/,
    rewriteReplaceString: "/en/blogs/$1/edit",

    redirectPattern: /^\/en\/blogs\/(.*)\/modifier/,
    redirectReplaceString: "/en/blogs/$1/edit",
  },
  {
    rewritePattern: /^\/fr\/blogs\/creer/,
    rewriteReplaceString: "/fr/blogs/create",

    redirectPattern: /^\/fr\/blogs\/create/,
    redirectReplaceString: "/fr/blogs/creer",
  },
  {
    rewritePattern: /^\/en\/blogs\/creer/,
    rewriteReplaceString: "/en/blogs/create",

    redirectPattern: /^\/en\/blogs\/creer/,
    redirectReplaceString: "/en/blogs/create",
  },
  {
    rewritePattern: /^\/fr\/blogs\/decouvrir/,
    rewriteReplaceString: "/fr/blogs/discover",

    redirectPattern: /^\/fr\/blogs\/discover/,
    redirectReplaceString: "/fr/blogs/decouvrir",
  },
  {
    rewritePattern: /^\/en\/blogs\/decouvrir/,
    rewriteReplaceString: "/en/blogs/discover",

    redirectPattern: /^\/en\/blogs\/decouvrir/,
    redirectReplaceString: "/en/blogs/discover",
  },
  {
    rewritePattern: /^\/fr\/petitions\/(.*)\/modifier/,
    rewriteReplaceString: "/fr/petitions/$1/edit",

    redirectPattern: /^\/fr\/petitions\/(.*)\/edit/,
    redirectReplaceString: "/fr/petitions/$1/modifier",
  },
  {
    rewritePattern: /^\/en\/petitions\/(.*)\/modifier/,
    rewriteReplaceString: "/en/petitions/$1/edit",

    redirectPattern: /^\/en\/petitions\/(.*)\/modifier/,
    redirectReplaceString: "/en/petitions/$1/edit",
  },
  {
    rewritePattern: /^\/fr\/petitions\/creer/,
    rewriteReplaceString: "/fr/petitions/create",

    redirectPattern: /^\/fr\/petitions\/create/,
    redirectReplaceString: "/fr/petitions/creer",
  },
  {
    rewritePattern: /^\/en\/petitions\/creer/,
    rewriteReplaceString: "/en/petitions/create",

    redirectPattern: /^\/en\/petitions\/creer/,
    redirectReplaceString: "/en/petitions/create",
  },
  {
    rewritePattern: /^\/fr\/petitions\/decouvrir/,
    rewriteReplaceString: "/fr/petitions/discover",

    redirectPattern: /^\/fr\/petitions\/discover/,
    redirectReplaceString: "/fr/petitions/decouvrir",
  },
  {
    rewritePattern: /^\/en\/petitions\/decouvrir/,
    rewriteReplaceString: "/en/petitions/discover",

    redirectPattern: /^\/en\/petitions\/decouvrir/,
    redirectReplaceString: "/en/petitions/discover",
  },
  {
    rewritePattern: /^\/fr\/profil\/(.*)/,
    rewriteReplaceString: "/fr/profiles/$1",

    redirectPattern: /^\/fr\/profiles\/(.*)/,
    redirectReplaceString: "/fr/profil/$1",
  },
  {
    rewritePattern: /^\/en\/profil\/(.*)/,
    rewriteReplaceString: "/en/profiles/$1",

    redirectPattern: /^\/en\/profil\/(.*)/,
    redirectReplaceString: "/en/profiles/$1",
  },
  {
    rewritePattern: /^\/fr\/modifier-profil/,
    rewriteReplaceString: "/fr/edit-profile",

    redirectPattern: /^\/fr\/edit-profile/,
    redirectReplaceString: "/fr/modifier-profil",
  },
  {
    rewritePattern: /^\/en\/modifier-profil/,
    rewriteReplaceString: "/en/edit-profile",

    redirectPattern: /^\/en\/modifier-profil/,
    redirectReplaceString: "/en/edit-profile",
  },
  {
    rewritePattern: /^\/fr\/reglages\/compte/,
    rewriteReplaceString: "/fr/settings/account",

    redirectPattern: /^\/fr\/settings\/account/,
    redirectReplaceString: "/fr/reglages/compte",
  },
  {
    rewritePattern: /^\/en\/reglages\/compte/,
    rewriteReplaceString: "/en/settings/account",

    redirectPattern: /^\/en\/reglages\/compte/,
    redirectReplaceString: "/en/settings/account",
  },
  {
    rewritePattern: /^\/fr\/reglages\/confidentialite/,
    rewriteReplaceString: "/fr/settings/privacy",

    redirectPattern: /^\/fr\/settings\/privacy/,
    redirectReplaceString: "/fr/reglages/confidentialite",
  },
  {
    rewritePattern: /^\/en\/reglages\/confidentialite/,
    rewriteReplaceString: "/en/settings/privacy",

    redirectPattern: /^\/en\/reglages\/confidentialite/,
    redirectReplaceString: "/en/settings/privacy",
  },
  {
    rewritePattern: /^\/fr\/reglages\/securite/,
    rewriteReplaceString: "/fr/settings/security",

    redirectPattern: /^\/fr\/settings\/security/,
    redirectReplaceString: "/fr/reglages/securite",
  },
  {
    rewritePattern: /^\/en\/reglages\/securite/,
    rewriteReplaceString: "/en/settings/security",

    redirectPattern: /^\/en\/reglages\/securite/,
    redirectReplaceString: "/en/settings/security",
  },
  {
    rewritePattern: /^\/fr\/reglages/,
    rewriteReplaceString: "/fr/settings",

    redirectPattern: /^\/fr\/settings/,
    redirectReplaceString: "/fr/reglages",
  },
  {
    rewritePattern: /^\/en\/reglages/,
    rewriteReplaceString: "/en/settings",

    redirectPattern: /^\/en\/reglages/,
    redirectReplaceString: "/en/settings",
  },
  {
    rewritePattern: /^\/fr\/compte-valide/,
    rewriteReplaceString: "/fr/account-ready",

    redirectPattern: /^\/fr\/account-ready/,
    redirectReplaceString: "/fr/compte-valide",
  },
  {
    rewritePattern: /^\/en\/compte-valide/,
    rewriteReplaceString: "/en/account-ready",

    redirectPattern: /^\/en\/compte-valide/,
    redirectReplaceString: "/en/account-ready",
  },
  {
    rewritePattern: /^\/fr\/completer-le-profil/,
    rewriteReplaceString: "/fr/onboarding",

    redirectPattern: /^\/fr\/onboarding/,
    redirectReplaceString: "/fr/completer-le-profil",
  },
  {
    rewritePattern: /^\/en\/completer-le-profil/,
    rewriteReplaceString: "/en/onboarding",

    redirectPattern: /^\/en\/completer-le-profil/,
    redirectReplaceString: "/en/onboarding",
  },
  {
    rewritePattern: /^\/fr\/confirmer-compte/,
    rewriteReplaceString: "/fr/confirm-account",

    redirectPattern: /^\/fr\/confirm-account/,
    redirectReplaceString: "/fr/confirmer-compte",
  },
  {
    rewritePattern: /^\/en\/confirmer-compte/,
    rewriteReplaceString: "/en/confirm-account",

    redirectPattern: /^\/en\/confirmer-compte/,
    redirectReplaceString: "/en/confirm-account",
  },
  {
    rewritePattern: /^\/fr\/communaute/,
    rewriteReplaceString: "/fr/community",

    redirectPattern: /^\/fr\/community/,
    redirectReplaceString: "/fr/communaute",
  },
  {
    rewritePattern: /^\/en\/communaute/,
    rewriteReplaceString: "/en/community",

    redirectPattern: /^\/en\/communaute/,
    redirectReplaceString: "/en/community",
  },
];
