/* eslint-disable import/no-anonymous-default-export */
const isLocalDev = process.env.NODE_ENV === "development";

export default {
  defaultNs: "translation",
  backendLoadPath: "/locales/{{lng}}/{{ns}}.json",
  isLocalDev,
  retrievePostsLimit: 10,
  uploadPicture: {
    maxNoOfFiles: 10,
    thumbnailMaxResolution: 200,
    profilePictureMaxResolution: 1024,
    profilePictureMaxSizeInBytes: 10485760, // 10MB
    pictureMaxSizeInBytes: 10000000, // 10MB
    coverPhotoMaxSizeInBytes: 10485760, // 10MB
    coverPhotoMinHeight: 255,
    supportedExtensions: [".jpg", ".jpeg", ".png"],
  },
  uploadVideo: {
    defaultMaxSize: 524288000, // 500MB
    increasedMaxSize: 3758096384, // 3.5GB
  },
  uploadFile: {
    defaultMaxSize: 524288000, // 500MB
    increasedMaxSize: 3758096384, // 3.5GB
    maxNoOfFiles: 10,
  },
  activityCoverLimits: {
    coverPhotoMaxSizeInBytes: 5242880, // 5MB
    coverPhotoMinHeight: 400,
  },
  maxPaginatedItems: 6,
  googleApiKey: "AIzaSyD_zTtM1iXbf0CmJ3uldISlznz1s1V5ta8",
};
