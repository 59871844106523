import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

// If we want this behavior only on specific pages / sections of the app,
// we would also need to include the French versions of the routes.

// We also need to decide if we want to split these routes into:
// - exact match routes (ONLY preserve the /groups page/list, not group pages themselves)
// - routes AND their sub-pages (for example every page in /profiles, when you're scrolling down anyone's timeline)
// unless we want them globally, or globally for some routes and sub-pages, but not others.
// Don't see a reason to why not have it globally for the entire app though.
const ROUTES_TO_RETAIN = [
  "/",
  "/groups",
  "/debates",
  "/events",
  "/blogs",
  "/petitions",
  "/livestreams",
  "/profiles",
  "/search",
];

export const useRestoreScroll = () => {
  const router = useRouter();
  const retainedRoutes = useRef<{ [key: string]: number }>({});

  const isRetainableRoute = ROUTES_TO_RETAIN.some((r) => router.asPath.startsWith(r));

  if (isRetainableRoute && !retainedRoutes.current[router.asPath]) {
    retainedRoutes.current[router.asPath] = 0;
  }

  const handleRouteChangeStart = () => {
    if (isRetainableRoute) {
      retainedRoutes.current[router.asPath] = window.scrollY;
    }
  };

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router.asPath]);

  useEffect(() => {
    if (isRetainableRoute) {
      const scrollingRepeater = setInterval(() => {
        window.scrollTo(0, retainedRoutes.current[router.asPath]);

        if (router.asPath === "/" || window.scrollY === retainedRoutes.current[router.asPath]) {
          clearInterval(scrollingRepeater);
        }
      }, 100);
    }
  }, [router]);
};
