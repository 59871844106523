import Router from "next/router";
import { useRef, useEffect, useState } from "react";

import { useActionCanceledMutation } from "@/graphql/types";

export const useActionCancellationMonitor = ({
  notifyAboutCancellation,
  formUpdated,
  message,
}: {
  notifyAboutCancellation?: boolean;
  formUpdated: boolean;
  message: string;
}) => {
  const willUnmount = useRef(false);
  const [changesSaved, setChangesSaved] = useState<boolean>(false);
  const [actionCanceled] = useActionCanceledMutation();

  useEffect(() => {
    return () => {
      willUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    const handleUnload = async () => {
      if (!notifyAboutCancellation || changesSaved) return;
      await actionCanceled();
    };
    return () => {
      if (willUnmount.current) {
        handleUnload();
      }
    };
  }, [changesSaved]);

  useEffect(() => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (!formUpdated) return;
      e.preventDefault();
      return (e.returnValue = message);
    };
    return () => {
      window.onbeforeunload = () => undefined;
    };
  }, [formUpdated]);

  useEffect(() => {
    const handler = (url: string) => {
      if (url.includes("action=cancel")) {
        setChangesSaved(true);
        return;
      }
      if (formUpdated && !changesSaved && !window.confirm(message)) {
        throw "Route Canceled";
      }
    };

    Router.events.on("routeChangeStart", handler);

    return () => {
      Router.events.off("routeChangeStart", handler);
    };
  }, [changesSaved, formUpdated]);

  return {
    setChangesSaved,
  };
};
