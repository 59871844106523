import React, { ReactNode } from "react";

import { Offline, PollingConfig } from "react-detect-offline";
import { useTranslation } from "react-i18next";

import { useAuthenticationStore } from "@/authentication/hooks";

import { useSnackbarContext } from "@/common/providers/SnackbarProvider";

interface InternetConnectivityProviderProps {
  children: ReactNode;
}

export const InternetConnectivityProvider = ({ children }: InternetConnectivityProviderProps) => {
  const { open } = useSnackbarContext();

  const { session } = useAuthenticationStore();
  const { t } = useTranslation();

  const pollingConfig = {
    interval: 20000,
  };

  const onNetworkChange = (online: boolean) => {
    const message = !!online
      ? t("Internet.Snackbar.connectionRestored")
      : t("Internet.Snackbar.connectionLost");
    open({
      open: true,
      message,
    });
  };
  return (
    <>
      {!!session?.user && (
        <Offline
          polling={pollingConfig as PollingConfig}
          onChange={(online) => onNetworkChange(online)}></Offline>
      )}
      {children}
    </>
  );
};
