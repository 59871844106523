import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import Router from "next/router";
import { useMemo } from "react";

import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { getMainDefinition, relayStylePagination } from "@apollo/client/utilities";
import { createLink } from "apollo-absinthe-upload-link";
import { split } from "apollo-link";
import merge from "lodash/merge";
import { Socket as PhoenixSocket } from "phoenix";
import Cookies from "universal-cookie";

import { Blog, blogQueryFields } from "@/blogs/type_policies";
import { ChatUser, chatQueryFields } from "@/chat/type_policies";
import { Comment, LiveStreamComment, CommentReply } from "@/comments/type_policies";
import { GRAPHQL_URL, WS_URL } from "@/common/constants";
import { overwriteServerSideProps } from "@/common/services/nextUtils";
import { usersToInviteQueryFields } from "@/common/type_policies/";
import { communityQueryFields } from "@/community/type_policies";
import { Debate, debateQueryFields } from "@/debates/type_policies";
import { discoverQueryFields } from "@/discover/type_policies";
import { Event, eventQueryFields } from "@/events/type_policies";
import generatedIntrospection, {
  CreateApiTokenDocument,
  CreateApiTokenMutationResult,
} from "@/graphql/types";
import { Group, groupQueryFields } from "@/groups/type_policies";
import { hotTrendsQueryFields } from "@/home/type_policies";
import { liveStreamQueryFields } from "@/liveStreams/type_policies";
import { LiveStream } from "@/liveStreams/type_policies/liveStream";
import { notificationsQueryFields } from "@/notifications/type_policies";
import { Petition, petitionQueryFields } from "@/petitions/type_policies";
import { Post } from "@/posts/type_policies/post";
import { profileQueryFields } from "@/profile/type_policies";
import { BlockedUser } from "@/profile/type_policies/list-blocked-users";
import { User } from "@/profile/type_policies/user";
import { searchQueryFields } from "@/search/type_policies";

let apolloClient: ApolloClient<NormalizedCacheObject>;
let prevInitialState: NormalizedCacheObject | null = null;

type Headers = GetServerSidePropsContext["req"]["headers"];

const getApolloClientLinks = (headers?: Headers | null) => {
  let wsLink;
  let errorCount = 0;
  let firstConnection = true;
  const cookie = new Cookies();
  const token = cookie.get("_politic_all_api_key");

  if (typeof window !== "undefined" && token) {
    const phoenixSocket = new PhoenixSocket(WS_URL, {
      params: () => {
        return { token };
      },
      heartbeatIntervalMs: 10000,
    });
    phoenixSocket.onClose(() => {
      firstConnection = false;
    });
    phoenixSocket.onOpen(async () => {
      if (!firstConnection) {
        const url = {
          pathname: Router.pathname,
          query: { ...Router.query, reconnectChat: 1 },
        };
        Router.push(url, undefined, { shallow: true });
      }

      errorCount = 0;
      const channel = phoenixSocket.channel("user_presence", { source: "web" });
      channel.join();
    });
    phoenixSocket.onError(() => {
      errorCount += 1;

      if (errorCount > 5) {
        const apolloClient = initializeApollo(headers);
        apolloClient.mutate({ mutation: CreateApiTokenDocument, variables: {} }).then((res) => {
          const token = (res as CreateApiTokenMutationResult).data?.createApiToken?.result?.token;
          if (token) {
            var sevenDaysFuture = new Date();
            sevenDaysFuture.setDate(sevenDaysFuture.getDate() + 7);
            cookie.set("_politic_all_api_key", token, {
              sameSite: "strict",
              expires: sevenDaysFuture,
              path: "/",
            });
            errorCount = 0;
            location.reload();
          }
        });
      }
    });
    const absintheSocket = AbsintheSocket.create(phoenixSocket);
    wsLink = createAbsintheSocketLink(absintheSocket);
  }

  const httpLink = createLink({
    uri: GRAPHQL_URL,
    headers,
    credentials: "include",
  });

  return wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" && definition.operation === "subscription"
          );
        },
        wsLink as any,
        httpLink,
      )
    : httpLink;
};

const getApolloClientCache = () =>
  new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          ...discoverQueryFields,
          ...groupQueryFields,
          ...debateQueryFields,
          ...eventQueryFields,
          ...blogQueryFields,
          ...petitionQueryFields,
          ...searchQueryFields,
          ...communityQueryFields,
          ...notificationsQueryFields,
          ...profileQueryFields,
          ...chatQueryFields,
          ...hotTrendsQueryFields,
          ...usersToInviteQueryFields,
          ...liveStreamQueryFields,
        },
      },
      User,
      BlockedUser,
      ChatUser,
      Group,
      Debate,
      Event,
      Blog,
      Petition,
      Follows: {
        fields: {
          followers: relayStylePagination(),
          peopleFollowedByThisUser: relayStylePagination(),
          followersFollowedByMe: relayStylePagination(),
        },
      },
      Post,
      LiveStream,
      Comment,
      LiveStreamComment,
      CommentReply,
    },
  });

const createApolloClient = (headers?: Headers | null) => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: getApolloClientLinks(headers),
    cache: getApolloClientCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-first",
        partialRefetch: true,
      },
      query: {
        fetchPolicy: "cache-first",
        partialRefetch: true,
      },
    },
  });
};

export function initializeApollo(
  headers: Headers | null | undefined,
  initialState: NormalizedCacheObject | null = null,
) {
  // Check if session is different from previous state,
  // so that we need to re-initialize apollo client
  const sessionChanged =
    initialState?.ROOT_QUERY?.session !== prevInitialState?.ROOT_QUERY?.session;

  const _apolloClient = sessionChanged
    ? createApolloClient(headers)
    : apolloClient ?? createApolloClient(headers);

  prevInitialState = initialState;

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore(merge(existingCache, initialState));
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient || sessionChanged) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useInitialApollo(initialState?: NormalizedCacheObject) {
  const store = useMemo(() => initializeApollo(null, initialState), [initialState]);
  return store;
}

export type ApolloContextExtend = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

export type apolloErrorContext = { graphQLErrors: [{ message: string }] };

export const withServerSideApolloClient =
  <C extends GetServerSidePropsContext, P>(
    handler: (
      ctx: C & ApolloContextExtend,
    ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>,
  ) =>
  async (ctx: C & { apolloClient?: ApolloClient<NormalizedCacheObject> }) => {
    if (!ctx.apolloClient) {
      const apolloClient = initializeApollo(ctx.req.headers);
      (ctx as C & ApolloContextExtend).apolloClient = apolloClient;
    }

    const handlerProps = await handler(ctx as C & ApolloContextExtend);

    return overwriteServerSideProps(handlerProps, {
      initialApolloState: (ctx.apolloClient as ApolloClient<NormalizedCacheObject>).cache.extract(),
    });
  };
