import { useState } from "react";

import Cookies from "js-cookie";
import groupBy from "lodash/groupBy";
import { useTranslation } from "react-i18next";

import {
  RegistrationInput,
  SessionFragment,
  UpdateEmailMutationVariables,
  useUpdateEmailMutation,
  RequestPasswordResetMutationVariables,
  useRequestPasswordResetMutation,
  useResendUpdateEmailMutation,
  useResendConfirmationEmailMutation,
  GetSessionProfileDocument,
  useGetSessionProfileQuery,
  useSignOutMutation,
  useLoginMutation,
  LoginMutationVariables,
  useSignUpMutation,
  useValidateEmailLazyQuery,
  useValidateUsernameLazyQuery,
  useResetPasswordUpdateMutation,
  useUserExistsLazyQuery,
  ResetPasswordUpdateMutationVariables,
  useCreateSmSessionMutation,
  CreateSmSessionMutationVariables,
  useCreateSmUserMutation,
  SmRegistrationInput,
  useValidPasswordLazyQuery,
} from "@/graphql/types";

export const useAuthenticationStore = (initialSession?: SessionFragment) => {
  const { loading, data, refetch } = useGetSessionProfileQuery();
  const { t } = useTranslation();
  const [signOut] = useSignOutMutation();
  const [validPasswordAttempt, setValidPasswordAttempt] = useState(0);
  const [login, { loading: loginLoading, data: loginData }] = useLoginMutation();
  const [createUser, { loading: signUpLoading, data: signUpData }] = useSignUpMutation();
  const [updateEmail, { loading: updateEmailLoading, data: updateEmailData }] =
    useUpdateEmailMutation();
  const [resendUpdateEmail] = useResendUpdateEmailMutation();
  const [resendConfirmationEmail] = useResendConfirmationEmailMutation();
  const [requestPasswordReset, { loading: resetPasswordLoading, data: resetPasswordData }] =
    useRequestPasswordResetMutation();
  const [
    resetPasswordUpdate,
    { loading: resetPasswordUpdateLoading, data: resetPasswordUpdateData },
  ] = useResetPasswordUpdateMutation();
  const [validateEmail, { data: validateEmailData, loading: validateEmailLoading }] =
    useValidateEmailLazyQuery();
  const [validateUsername, { data: useValidateUsernameData, loading: validateUsernameLoading }] =
    useValidateUsernameLazyQuery();

  // Social Media Sign-in
  const [userExists, { data: userExistsData }] = useUserExistsLazyQuery();
  const [validPassword, { data: validPasswordData, loading: validPasswordLoading }] =
    useValidPasswordLazyQuery({
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: () => {
        setValidPasswordAttempt(validPasswordAttempt + 1);
      },
    });
  const [createSmSession, { loading: createSmSessionLoading, data: createSmSessionData }] =
    useCreateSmSessionMutation();
  const [smCreateUser, { loading: smCreateUserLoading, data: smCreateUserData }] =
    useCreateSmUserMutation();

  return {
    userExists,
    userExistsData,
    loading,
    refetch,
    loginLoading,
    updateEmailLoading,
    signUpLoading,
    smCreateUserData,
    smCreateUserLoading,
    createSmSessionLoading,
    resetPasswordLoading,
    resetPasswordUpdateLoading,
    resetPasswordUpdateData,
    successfulUpdateEmail: updateEmailData?.updateEmail?.successful,
    successfulLogin: loginData?.createSession?.successful,
    successfulPasswordUpdate: updateEmailData?.updateEmail?.successful,
    successfulSignUp: signUpData?.createUser?.successful,
    successfulSmSignUp: smCreateUserData?.smCreateUser?.successful,
    get updateEmailError() {
      if (
        updateEmailData?.updateEmail?.messages &&
        updateEmailData?.updateEmail?.messages.length > 0
      ) {
        switch (updateEmailData?.updateEmail?.messages[0]?.message) {
          case "Email already taken":
            return { field: "email", message: t("App.Settings.ChangeEmailEmailAlreadyTaken") };
          case "Invalid password":
            return { field: "password", message: t("App.Settings.ChangeEmail.InvalidPassword") };
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    get signUpErrors() {
      return groupBy(signUpData?.createUser?.messages, (message) => message?.field);
    },
    successfulResetPassword: resetPasswordData?.requestPasswordReset?.successful,

    get session() {
      if (data) {
        return data.session;
      } else if (initialSession) {
        return initialSession;
      }
    },
    get isUserLoggedIn() {
      if (data) {
        return data.session !== undefined && data.session !== null;
      }
      return false;
    },
    get isUserModerator() {
      if (data) {
        return data.session?.user?.id !== undefined && data.session.user.isModerator;
      }
      return false;
    },

    signOut() {
      return signOut({
        update: () => {
          Cookies.remove("_politic_all_api_key");
        },
      });
    },
    login({ email, password, rememberMe, otpCode }: LoginMutationVariables) {
      return login({
        variables: { email, password, rememberMe, otpCode },
        update: (_, { data }) => {
          data?.createSession?.result?.apiToken &&
            Cookies.set("_politic_all_api_key", data.createSession.result.apiToken, {
              sameSite: "Strict",
              expires: 365,
              path: "/",
            });
        },
      });
    },
    smLogin({ firebaseUid, email, otpCode, rememberMe }: CreateSmSessionMutationVariables) {
      return createSmSession({
        variables: { firebaseUid, email, otpCode, rememberMe },
        update: (_, { data }) => {
          data?.createSmSession?.result?.apiToken &&
            Cookies.set("_politic_all_api_key", data.createSmSession.result.apiToken, {
              sameSite: "Strict",
              expires: 365,
              path: "/",
            });
        },
      });
    },
    smLoginData: createSmSessionData,

    signUp(data: RegistrationInput) {
      return createUser({ variables: { data } });
    },
    smSignUp(data: SmRegistrationInput) {
      return smCreateUser({ variables: { data } });
    },
    validPasswordLoading,
    validPasswordData,
    validPasswordAttempt,
    validPassword(password: string) {
      return validPassword({ variables: { password } });
    },
    updateEmail({ email, password }: UpdateEmailMutationVariables) {
      return updateEmail({
        variables: { email, password },
        update: (cache, { data }) => {
          const prevProfile = cache.readQuery<{ session: SessionFragment }>({
            query: GetSessionProfileDocument,
          });

          if (prevProfile?.session?.user && data?.updateEmail?.successful) {
            cache.writeQuery({
              query: GetSessionProfileDocument,
              data: {
                ...prevProfile,
                session: {
                  ...prevProfile.session,
                  user: {
                    ...prevProfile.session.user,
                    updateEmailRequestFor: email,
                  },
                },
              },
            });
          }
        },
      });
    },
    resendUpdateEmail,
    resendConfirmationEmail,
    resetPassword({ email }: RequestPasswordResetMutationVariables) {
      return requestPasswordReset({ variables: { email } });
    },
    resetPasswordUpdate({
      token,
      password,
      passwordConfirmation,
    }: ResetPasswordUpdateMutationVariables) {
      return resetPasswordUpdate({ variables: { token, password, passwordConfirmation } });
    },
    validateEmail(email: string) {
      validateEmail({ variables: { email } });
    },
    validateEmailResult: validateEmailData?.validateEmail,
    validateEmailLoading,
    validateUsername(username: string) {
      validateUsername({ variables: { username } });
    },
    validateUsernameResult: useValidateUsernameData?.validateUsername,
    validateUsernameLoading,
  };
};
