import {
  UserFragment,
  UserFragmentDoc,
  GetProfileFollowersQueryVariables,
  useGetProfileFollowersQuery,
  useFollowUserMutation,
  useUnfollowUserMutation,
  useUndoFollowRequestMutation,
  UserFullFragment,
} from "@/graphql/types";

type UseProfileFollowersProps = {
  user?:
    | (Pick<UserFullFragment, "id" | "__typename"> & {
        follows: Pick<UserFullFragment["follows"], "isFollowedByMe" | "followId">;
      })
    | null
    | undefined; // This is only needed for mutations
  variables?: Partial<GetProfileFollowersQueryVariables>; // These are only needed for fetching the followers
};

export const useProfileFollowersStore = ({ user, variables }: UseProfileFollowersProps) => {
  const {
    loading,
    data,
    error,
    fetchMore: fetchNextFollowersPage,
  } = useGetProfileFollowersQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !variables || !variables.username || !variables.limit,
    variables:
      variables &&
      ({
        username: variables.username,
        limit: variables.limit,
        after: variables.after,
      } as GetProfileFollowersQueryVariables),
  });
  const [follow, { loading: followLoading }] = useFollowUserMutation();
  const [unfollow, { loading: unfollowLoading }] = useUnfollowUserMutation();
  const [undoFollowRequest, { loading: undoFollowRequestLoading }] = useUndoFollowRequestMutation();

  return {
    loading,
    initialized: !!data?.user?.follows,
    error: error !== undefined,
    hasMorePages: data?.user?.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.user?.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.user?.follows.followers.count || 0,
    loadingToggleFollow: followLoading || unfollowLoading || undoFollowRequestLoading,
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.user?.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    toggleFollow() {
      if (user) {
        if (user.follows.isFollowedByMe) {
          return unfollow({
            variables: {
              id: user.id,
            },
          });
        }
        return follow({
          variables: {
            id: user.id,
          },
        });
      }
    },
    undoFollowRequest() {
      if (user?.follows.followId) {
        return undoFollowRequest({
          variables: {
            id: user.follows.followId,
          },
          update(cache, { data }) {
            if (data?.undoFollowRequest?.successful) {
              const result = cache.readFragment({
                id: `${user.__typename}:${user.id}`,
                fragment: UserFragmentDoc,
                fragmentName: "User",
              }) as UserFragment;

              if (result) {
                cache.writeFragment({
                  id: `${user.__typename}:${user.id}`,
                  fragment: UserFragmentDoc,
                  fragmentName: "User",
                  data: {
                    ...result,
                    follows: {
                      ...result.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }
            }
          },
        });
      }
    },
  };
};
