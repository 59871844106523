import { useAuthenticationStore } from "@/authentication/hooks";

import {
  DebateInput,
  GetDebatesDocument,
  GetDebatesQuery,
  GetDebatesQueryVariables,
  useGetDebateQuery,
  useGetDebatesQuery,
  UserFullFragment,
  useCreateDebateMutation,
  useUpdateDebateMutation,
  useDeleteDebateMutation,
  useCloseDebateMutation,
} from "@/graphql/types";

type UseDebatesStoreProps = {
  slug?: string;
  fetch?: boolean;
  variables?: {
    closed?: boolean;
    user?: UserFullFragment;
    limit: number;
  };
  fetchBySlug?: boolean;
};

export const useDebatesStore = ({
  slug,
  fetch,
  variables,
  fetchBySlug,
}: UseDebatesStoreProps = {}) => {
  const { session } = useAuthenticationStore();
  const {
    loading: fetchLoading,
    data,
    error: fetchError,
  } = useGetDebateQuery({ skip: !slug, variables: { slug: slug as string } });

  const {
    loading: fetchAllLoading,
    data: dataAll,
    error: fetchAllError,
    fetchMore: fetchNextDebatesPage,
  } = useGetDebatesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !fetch,
    variables: variables
      ? fetchBySlug
        ? {
            authorSlug: variables.user
              ? variables.user.slug
              : session?.user
              ? session.user.slug
              : "politicall",
            closed: variables.closed,
            limit: variables.limit,
          }
        : {
            authorId: variables.user
              ? variables.user.id
              : session?.user
              ? session.user.id
              : "politicall",
            closed: variables.closed,
            limit: variables.limit,
          }
      : undefined,
  });
  const [createDebate, { loading: createLoading }] = useCreateDebateMutation();
  const [updateDebate, { loading: updateLoading }] = useUpdateDebateMutation();
  const [deleteDebate, { loading: deleteLoading }] = useDeleteDebateMutation();
  const [closeDebate, { loading: closeLoading }] = useCloseDebateMutation();

  return {
    get debate() {
      if (data) {
        return data.debate;
      }
    },
    get debates() {
      if (dataAll) {
        return dataAll.debates.edges.map(({ node }) => node);
      }
      return [];
    },
    fetchNextDebatesPage() {
      if (fetchNextDebatesPage && dataAll) {
        return fetchNextDebatesPage({
          variables: {
            after: dataAll.debates.pageInfo.endCursor,
          },
        });
      }
    },
    create(data: DebateInput) {
      return createDebate({
        variables: {
          data,
        },
        update(cache, { data }) {
          if (session && session.user) {
            const result = cache.readQuery<GetDebatesQuery, GetDebatesQueryVariables>({
              query: GetDebatesDocument,
              variables: {
                authorId: session.user.id,
                closed: false,
                limit: 6,
              },
            });

            if (result) {
              cache.writeQuery({
                query: GetDebatesDocument,
                variables: {
                  authorId: session.user.id,
                  closed: false,
                  limit: 6,
                },
                data: {
                  ...result,
                  debates: {
                    ...result.debates,
                    edges: [
                      ...result.debates.edges,
                      {
                        node: data?.createDebate?.result,
                      },
                    ],
                  },
                },
              });
            }
          }
        },
      }).then(({ data }) => {
        if (data?.createDebate?.successful) {
          return data?.createDebate?.result;
        } else {
          throw data?.createDebate;
        }
      });
    },
    update(debateId: string, data: DebateInput) {
      return updateDebate({
        variables: {
          id: debateId,
          data,
        },
      });
    },
    destroy(debateId: string) {
      return deleteDebate({
        variables: {
          id: debateId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: debateId,
            __typename: "Debate",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    close(debateId: string) {
      return closeDebate({
        variables: {
          id: debateId,
        },
      });
    },
    get loading() {
      return (
        fetchAllLoading ||
        fetchLoading ||
        createLoading ||
        updateLoading ||
        deleteLoading ||
        closeLoading
      );
    },
    get error() {
      return fetchError !== undefined || fetchAllError !== undefined;
    },
    get hasMorePages() {
      if (dataAll) {
        return dataAll.debates.pageInfo.hasNextPage;
      }
      return false;
    },
  };
};
