import { BlogInput, useGetBlogQuery, useUpdateBlogMutation } from "@/graphql/types";

export const useBlogStore = (slug?: string) => {
  const { loading, data, error } = useGetBlogQuery({
    variables: { slug: slug as string },
    skip: !slug,
  });

  const [updateBlog, { loading: updateLoading }] = useUpdateBlogMutation();

  return {
    blog: data?.blog,
    loading,
    updateLoading,
    error,
    update({ id, data }: { id: string; data: BlogInput }) {
      return updateBlog({
        variables: {
          id,
          data,
        },
      });
    },
  };
};
