import React, { ReactNode } from "react";

import { ThemeProvider } from "@mui/material";

import { useAuthenticationStore } from "@/authentication/hooks";

import { createThemeObject } from "@/common/services/theme";
import { ThemeMode } from "@/graphql/types";

interface CustomThemeProviderProps {
  children: ReactNode;
}

export const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => {
  const { session } = useAuthenticationStore();

  return (
    <ThemeProvider
      theme={createThemeObject(
        session?.user?.themeColor || "orange",
        session?.user?.themeMode || ThemeMode.Light,
      )}>
      {children}
    </ThemeProvider>
  );
};
