/**
 * Given an array of Google Maps address components, it will parse it, remove
 * unwanted components and return a string with the new sanitized address.
 *
 * @param {google.maps.GeocoderAddressComponent[]} addressComponents
 */
export const getFormattedAddress = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  // We exclude address components we don't need, like street, street number etc
  const ALLOWED_ADDRESS_COMPONENTS = [
    "postal_code",
    "locality",
    "administrative_area_level_1",
    "country",
  ];

  let formattedAddress = "";

  // Filter for only valid adress components
  const validComponents = addressComponents.filter((item) => {
    return item.types.some((item) => ALLOWED_ADDRESS_COMPONENTS.includes(item));
  });

  ALLOWED_ADDRESS_COMPONENTS.map((component) => {
    const componentValue = validComponents.find((addrc) =>
      addrc.types.includes(component),
    )?.long_name;

    switch (true) {
      case componentValue && component === "postal_code":
        formattedAddress += componentValue + " ";
        break;
      case componentValue && component === "country":
        formattedAddress += componentValue;
        break;

      case typeof componentValue !== "undefined":
        formattedAddress += componentValue + ", ";
        break;

      default:
        break;
    }
  });

  return formattedAddress;
};
