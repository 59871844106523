import { useRouter } from "next/router";
import React, { createContext, useState, FC, useEffect, useContext } from "react";

type BindReturnProps = { open: boolean; onClose: () => void };
type RoutedDialogProps = {
  bind: (dialogName: string) => BindReturnProps;
  open: (dialogName: string, voteOrientation?: string) => void;
  close: () => void;
  isOpen: (dialogName: string) => boolean;
  voteOrientation?: string;
};

export const RoutedDialogContext = createContext<RoutedDialogProps>({} as RoutedDialogProps);

export const RoutedDialogProvider: FC = ({ children }) => {
  const router = useRouter();
  const [openDialogName, setOpenDialogName] = useState<string | null>(null);
  const open = (dialogName: string, voteOrientation?: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("popup", dialogName);
    if (voteOrientation) {
      url.searchParams.set("voteOrientation", voteOrientation);
    }

    router.push(url, undefined, { shallow: true });
  };
  const close = () => {
    setOpenDialogName(null);
    const url = new URL(window.location.href);
    url.searchParams.delete("popup");
    url.searchParams.delete("voteOrientation");

    router.push(url, undefined, { shallow: true });
  };
  const bind = (dialogName: string) => {
    return {
      open: dialogName === openDialogName,
      onClose: close,
    };
  };
  const isOpen = (dialogName: string) => {
    return dialogName === openDialogName;
  };
  const { popup } = router.query;
  useEffect(() => {
    setOpenDialogName(popup ? (popup as string) : null);
  }, [popup]);

  return (
    <RoutedDialogContext.Provider value={{ bind, open, close, isOpen }}>
      {children}
    </RoutedDialogContext.Provider>
  );
};

export const useRoutedDialog = () => useContext(RoutedDialogContext);
