export type colorMode = {
  carousel: {
    next: string;
    previous: string;
  };
  text: {
    primary: string;
    secondary: string;
    heading: string;
    commentBody: string;
    description: string;
    disabled: string;
    formLabel: string;
    title: string;
    subtitle: string;
    contrast: string;
  };
  debate: {
    no: string;
  };
  background: {
    coverButton: string;
    default: string;
    light: string;
    lighter: string;
    calendarCell: string;
    comment: string;
    commentHighlighted: string;
    input: string;
    popover: string;
    snackbar: string;
    snackbarError: string;
    menu: {
      default: string;
      hover: string;
    };
    skeleton: string;
  };
  icon: {
    default: string;
    light: string;
    dark: string;
    backgroundLight: string;
    background: string;
    backgroundSecondary: string;
    quill: string;
    activityHeader: string;
  };
  shadow: {
    dialog: string;
    bigDialog: string;
    dialogHeader: string;
    card: string;
    smallCard: string;
    popover: string;
    bottomBar: string;
  };
  tooltip: {
    background: string;
    text: string;
  };
  separator: {
    list: string;
  };
};

type colorModeProps = {
  light: colorMode;
  dark: colorMode;
};

export const colorModes: colorModeProps = {
  // Light mode
  light: {
    carousel: {
      next: "linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(247,247,247,1) 100%)",
      previous: "linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(247,247,247,1) 100%)",
    },
    text: {
      primary: "#5e6875",
      secondary: "#272727",
      heading: "#3f3d56",
      commentBody: "#61606c",
      description: "#6c6b78",
      disabled: "#a9a9b2",
      formLabel: "#7d7d8c",
      title: "#353348",
      subtitle: "#a9a9b2",
      contrast: "#ffffff",
    },
    debate: {
      no: "#3f3d56",
    },
    background: {
      coverButton: "#dadada",
      default: "#f7f7f7",
      light: "#fff",
      lighter: "#f6f6f6",
      calendarCell: "#fff",
      comment: "#ebebeb",
      commentHighlighted: "#d4d4d4",
      input: "#f0f0f0",
      popover: "#fff",
      snackbar: "#3f3d56",
      snackbarError: "#ff3333",
      menu: {
        default: "#fff",
        hover: "#f7f7f7",
      },
      skeleton: "rgba(0,0,0,0.11)",
    },
    icon: {
      default: "#6c6b78",
      light: "#bebec5",
      dark: "#494952",
      backgroundLight: "#bebec5",
      background: "#3f3d56",
      backgroundSecondary: "#e4e4e4",
      quill: "#444",
      activityHeader: "#f0f0f0",
    },
    shadow: {
      dialog: "0 2px 16px 0 rgba(63, 61, 86, 0.12)",
      bigDialog: "0 0 24px 0 rgba(63, 61, 86, 0.12)",
      dialogHeader: "0 2px 16px 0 rgba(63, 61, 86, 0.08)",
      card: "0 0px 20px -8px rgb(190 190 197)",
      smallCard: "0 0 16px 0 rgba(63, 61, 86, 0.08)",
      popover: "0 2px 16px 0 rgba(63, 61, 86, 0.16)",
      bottomBar: "0 0 16px 0 rgba(63, 61, 86, 0.12)",
    },
    tooltip: {
      background: "#494952",
      text: "#f6f6f6",
    },
    separator: {
      list: "#d8d8d8",
    },
  },
  // Dark mode
  dark: {
    carousel: {
      next: "linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(53,51,72,1) 100%)",
      previous: "linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(53,51,72,1) 100%)",
    },
    text: {
      primary: "#f0f0f0",
      secondary: "#e4e4e4",
      heading: "#f0f0f0",
      commentBody: "#f0f0f0",
      description: "#e4e4e4",
      disabled: "#a9a9b2",
      formLabel: "#e4e4e4",
      title: "#ffffff",
      subtitle: "#a9a9b2",
      contrast: "#353348",
    },
    debate: {
      no: "#353348",
    },
    background: {
      coverButton: "#444060",
      default: "#353348",
      light: "#3f3d56",
      lighter: "#4f4c6b",
      calendarCell: "#3f3d56",
      comment: "#4f4c6b",
      commentHighlighted: "#5f5c7b",
      input: "#4f4c6b",
      popover: "#4f4c6b",
      snackbar: "#ffffff",
      snackbarError: "#ff3333",
      menu: {
        default: "#4f4c6b",
        hover: "#3f3d56",
      },
      skeleton: "rgba(255,255,255,0.11)",
    },
    icon: {
      default: "#f6f6f6",
      light: "#bebec5",
      dark: "#f0f0f0",
      backgroundLight: "#4f4c6b",
      background: "#6c6b78",
      backgroundSecondary: "#4f4c6b",
      quill: "#bbb",
      activityHeader: "#353348",
    },
    shadow: {
      dialog: "0 2px 16px 0 rgba(0, 0, 0, 0.22)",
      bigDialog: "0 0 24px 0 rgba(0, 0, 0, 0.16)",
      dialogHeader: "0 2px 16px 0 rgba(0, 0, 0, 0.12)",
      card: "0 0px 20px -8px rgba(0, 0, 0, 0.12)",
      smallCard: "0 4px 16px 0 rgba(0, 0, 0, 0.1)",
      popover: "0 2px 16px 0 rgba(0, 0, 0, 0.16)",
      bottomBar: "0 0 16px 0 rgba(63, 61, 86, 0.12)",
    },
    tooltip: {
      background: "#e4e4e4",
      text: "#353348",
    },
    separator: {
      list: "#4f4c6b",
    },
  },
};
