import router from "next/router";
import { useEffect } from "react";

export function useMentionLinks() {
  useEffect(() => {
    window.addEventListener(
      "mention-clicked",
      (event) => {
        router.push(`/profiles/${(event as any).value.slug}`);
      },
      false,
    );
  }, []);
}
