import { followedPetitions } from "./followed-petitions";
import { Petition } from "./petition";
import { petitions } from "./petitions";

export const petitionQueryFields = {
  petitions,
  followedPetitions,
};

export { Petition };
