import dynamic from "next/dynamic";
import React, { createContext, useState, FC, useContext } from "react";

import { useTranslation } from "react-i18next";

import { useBlogsFollowersStore } from "@/blogs/hooks";
import { useDebatesFollowersStore } from "@/debates/hooks";
import { useEventsFollowersStore } from "@/events/hooks";
import { useGroupsFollowersStore } from "@/groups/hooks";
import { useLiveStreamFollowersStore } from "@/liveStreams/hooks/use-livestream-followers-store";
import { usePetitionsFollowersStore } from "@/petitions/hooks";

import { ExtendedActivityType } from "@/graphql/types";

import FollowUserButton from "../components/FollowUserButton";
import { UserListModalProps } from "../components/UserListModal/UserListModal";

type ModalProviderContextProps = {
  open: (id: string, activityType: ExtendedActivityType, numberFollowers: number) => void;
  close: () => void;
  isOpen: boolean;
};

export const ModalProviderContext = createContext<ModalProviderContextProps>(
  {} as ModalProviderContextProps,
);

const UserListModal = dynamic<UserListModalProps>(
  () => import("../components/UserListModal/UserListModal").then((module) => module.UserListModal),
  {
    ssr: false,
  },
);

export const ModalProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<ExtendedActivityType | null>(null);
  const [followersText, setFollowersText] = useState<string>("");

  const { t } = useTranslation();

  const {
    followers: groupFollowers,
    fetchFollowersById: fetchGroupFollowersById,
    hasMorePages: hasMoreGroupFollowers,
    fetchNextFollowersPage: fetchNextGroupFollowers,
  } = useGroupsFollowersStore();

  const {
    fetchFollowersById: fetchDebateFollowersById,
    followers: debateFollowers,
    hasMorePages: hasMoreDebateFollowers,
    fetchNextFollowersPage: fetchMoreDebateFollowers,
  } = useDebatesFollowersStore();

  const {
    followers: eventFollowers,
    fetchFollowersById: fetchEventFollowersById,
    hasMorePages: hasMoreEventFollowers,
    fetchNextFollowersPage: fetchMoreEventsFollowers,
  } = useEventsFollowersStore();

  const {
    followers: blogFollowers,
    fetchFollowersById: fetchBlogFollowersById,
    hasMorePages: hasMoreBlogFollowers,
    fetchNextFollowersPage: fetchMoreBlogFollowers,
  } = useBlogsFollowersStore();

  const {
    followers: petitionFollowers,
    fetchFollowersById: fetchPetitionFollowersById,
    hasMorePages: hasMorePetitionFollowers,
    fetchNextFollowersPage: fetchMorePetitionFollowers,
  } = usePetitionsFollowersStore();

  const {
    followers: livestreamFollowers,
    fetchFollowersById: fetchLivestreamFollowersById,
    hasMorePages: hasMoreLivestreamFollowers,
    fetchNextFollowersPage: fetchMoreLivestreamFollowers,
  } = useLiveStreamFollowersStore();

  const open = (id: string, activityType: ExtendedActivityType, numberFollowers: number) => {
    setActivityType(activityType);
    switch (activityType) {
      case ExtendedActivityType.Group:
        fetchGroupFollowersById({ id: id, limit: 20 });
        setFollowersText(
          numberFollowers === 1
            ? t("Common.member_count", { count: numberFollowers })
            : t("Common.member_count_plural", { count: numberFollowers }),
        );
        break;
      case ExtendedActivityType.Debate:
        fetchDebateFollowersById({ id: id, limit: 20 });
        setFollowersText(
          numberFollowers === 1
            ? t("Common.participant_count", { count: numberFollowers })
            : t("Common.participant_count_plural", { count: numberFollowers }),
        );
        break;
      case ExtendedActivityType.Event:
        fetchEventFollowersById({ id: id, limit: 20 });
        setFollowersText(
          t("Event.PeopleAttending", {
            count: numberFollowers,
          }),
        );
        break;
      case ExtendedActivityType.Blog:
        fetchBlogFollowersById({ id: id, limit: 20 });
        setFollowersText(
          numberFollowers === 1
            ? t("Common.follower_count", { count: numberFollowers })
            : t("Common.follower_count_plural", { count: numberFollowers }),
        );
        break;
      case ExtendedActivityType.Petition:
        fetchPetitionFollowersById({ id: id, limit: 20 });
        setFollowersText(
          numberFollowers === 1
            ? t("Common.signatories_countOne", { count: numberFollowers })
            : t("Common.signatories_countOther", { count: numberFollowers }),
        );
        break;
      case ExtendedActivityType.LiveStream:
        fetchLivestreamFollowersById({ id: id, limit: 20 });
        setFollowersText(
          numberFollowers === 1
            ? t("Common.follower_count", { count: numberFollowers })
            : t("Common.follower_count_plural", { count: numberFollowers }),
        );
        break;
      default:
        break;
    }
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <ModalProviderContext.Provider value={{ open, close, isOpen }}>
      <>
        {activityType && activityType === ExtendedActivityType.Group && (
          <UserListModal
            open={isOpen}
            onClose={() => close()}
            title={followersText}
            users={groupFollowers}
            headerLeftAction={<></>}
            hasMoreUsers={hasMoreGroupFollowers}
            fetchMoreUsers={fetchNextGroupFollowers}
            UserAction={FollowUserButton}
          />
        )}

        {activityType && activityType === ExtendedActivityType.Debate && (
          <UserListModal
            open={isOpen}
            onClose={() => close()}
            title={followersText}
            users={debateFollowers}
            headerLeftAction={<></>}
            hasMoreUsers={hasMoreDebateFollowers}
            fetchMoreUsers={fetchMoreDebateFollowers}
            UserAction={FollowUserButton}
          />
        )}

        {activityType && activityType === ExtendedActivityType.Event && (
          <UserListModal
            open={isOpen}
            onClose={() => close()}
            title={followersText}
            users={eventFollowers}
            headerLeftAction={<></>}
            hasMoreUsers={hasMoreEventFollowers}
            fetchMoreUsers={fetchMoreEventsFollowers}
            UserAction={FollowUserButton}
          />
        )}

        {activityType && activityType === ExtendedActivityType.Blog && (
          <UserListModal
            open={isOpen}
            onClose={() => close()}
            title={followersText}
            users={blogFollowers}
            headerLeftAction={<></>}
            hasMoreUsers={hasMoreBlogFollowers}
            fetchMoreUsers={fetchMoreBlogFollowers}
            UserAction={FollowUserButton}
          />
        )}

        {activityType && activityType === ExtendedActivityType.Petition && (
          <UserListModal
            open={isOpen}
            onClose={() => close()}
            title={followersText}
            users={petitionFollowers}
            headerLeftAction={<></>}
            hasMoreUsers={hasMorePetitionFollowers}
            fetchMoreUsers={fetchMorePetitionFollowers}
            UserAction={FollowUserButton}
          />
        )}

        {activityType && activityType === ExtendedActivityType.LiveStream && (
          <UserListModal
            open={isOpen}
            onClose={() => close()}
            title={followersText}
            users={livestreamFollowers}
            headerLeftAction={<></>}
            hasMoreUsers={hasMoreLivestreamFollowers}
            fetchMoreUsers={fetchMoreLivestreamFollowers}
            UserAction={FollowUserButton}
          />
        )}
        {children}
      </>
    </ModalProviderContext.Provider>
  );
};

export const useModalProvider = () => useContext(ModalProviderContext);
