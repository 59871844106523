import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";

import { ApolloProvider } from "@apollo/client";
import createCache, { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

import Gdpr from "@/common/components/Gdpr";
import { GlobalStyles } from "@/common/components/GlobalStyles";
import I18n from "@/common/components/I18n";

import { useMentionLinks } from "@/common/hooks/use-mention-links";
import { useRestoreScroll } from "@/common/hooks/use-restore-scroll";

import { useInitialApollo } from "@/apollo/client";
import { AppNestedProviders } from "@/common/providers/AppNestedProviders";
import { DEFAULT_FEATURE_FLAGS } from "@/feature-flags/constants/flags";
import { FlagsProvider } from "@/feature-flags/contexts/FlagsContext";

import "../styles/reset.css";
import "../styles/PhotoCarousel.css";
import "../styles/Carousel.css";
import "croppie/croppie.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "quill-mention/dist/quill.mention.css";
import "../styles/Quill.css";

import "moment/locale/fr";

let emotionCache: EmotionCache | undefined = undefined;

export const createEmotionCache = () => (emotionCache = createCache({ key: "mui", prepend: true }));

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useInitialApollo(pageProps.initialApolloState);
  const router = useRouter();

  useMentionLinks();
  useRestoreScroll();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      try {
        // Google Tag
        (window as any).gtag("event", "page_view", {
          page_location: url,
        });
        // Hubspot
        (window as any)._hsq.push(["setPath", url]);
        (window as any)._hsq.push(["trackPageView"]);
      } catch {}

      // Twitter
      if ((window as any).twq) {
        (window as any).twq("track", "PageView");
      }
      // Snapchat
      if ((window as any).snaptr) {
        (window as any).snaptr("track", "PAGE_VIEW");
      }

      // Tiktok
      if (typeof (ttq as any) !== "undefined" && (ttq as any).page) {
        (ttq as any).page();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setVh();
    window.addEventListener("resize", setVh);
    window.addEventListener("orientationchange", setVh);
    return () => {
      window.removeEventListener("resize", setVh);
      window.removeEventListener("orientationchange", setVh);
    };
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <CacheProvider value={emotionCache ?? createEmotionCache()}>
      <ApolloProvider client={apolloClient}>
        <I18n />
        <FlagsProvider names={pageProps.featureFlagNames || DEFAULT_FEATURE_FLAGS}>
          <AppNestedProviders>
            <CssBaseline />
            <GlobalStyles />
            <Gdpr>
              <Component {...pageProps} />
            </Gdpr>
          </AppNestedProviders>
        </FlagsProvider>
      </ApolloProvider>
    </CacheProvider>
  );
}

export default MyApp;
