import { GetServerSidePropsResult, GetStaticPropsResult } from "next";

export const handleAdditionalServerSideProps = <P>(
  res: GetServerSidePropsResult<P>,
  additionalProps: Record<string, unknown>,
) => {
  if ((res as { props: P }).props) {
    return {
      props: {
        // we still want the props from the higer order function to be
        // overwriten by those from handler, so that if handler would make additional
        // requests to the apollo client, the initial state would be
        // taken from the inner function
        ...additionalProps,
        ...(res as { props: P }).props,
      },
    };
  } else {
    return res;
  }
};

// Inversion of the handleAdditionalServerSideProps
export const overwriteServerSideProps = <P>(
  res: GetServerSidePropsResult<P>,
  additionalProps: Record<string, unknown>,
) => {
  if ((res as { props: P }).props) {
    return {
      props: {
        ...(res as { props: P }).props,
        ...additionalProps,
      },
    };
  } else {
    return res;
  }
};

export type BetterInferGetStaticPropsType<T> = T extends (
  ...args: readonly any[]
) => Promise<GetStaticPropsResult<infer P>>
  ? NonNullable<P>
  : never;

export type BetterInferGetServerSidePropsType<T> = T extends (
  ...args: readonly any[]
) => Promise<GetServerSidePropsResult<infer P>>
  ? NonNullable<P>
  : BetterInferGetStaticPropsType<T>;

export type InferPagePropsType<T> = T extends (
  ...args: readonly any[]
) => Promise<{ readonly props: infer P } | { readonly notFound: boolean }>
  ? NonNullable<P>
  : BetterInferGetServerSidePropsType<T>;
