import React, { ComponentType, ReactNode } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { FeedbackContextProvider } from "@/slack-feedback/components/SlackFeedbackProvider";
import CustomTourProvider from "@/tour/components/CustomTourProvider";

import {
  ConsentProvider,
  SnackbarProvider,
  RoutedDialogProvider,
  InternetConnectivityProvider,
  OneSignalProvider,
} from "@/common/providers";
import { CustomThemeProvider } from "@/common/providers/CustomThemeProvider";
import { ModalProvider } from "@/common/providers/ModalProvider";
import { SubscriptionProvider } from "@/monetization/providers/SubscriptionProvider";

type Provider = {
  component: ComponentType<any>;
  props: Record<string, any>;
};

const combineComponents = (...components: Provider[]) => {
  return components.reduce(
    (AccumulatedComponents, { component: CurrentComponent, props }) => {
      const NextComponent = ({ children }: { children: ReactNode }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent {...props}>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
      return NextComponent;
    },
    ({ children }: { children: ReactNode }) => <>{children}</>,
  );
};

const providers = [
  { component: CustomThemeProvider, props: {} },
  { component: ConsentProvider, props: {} },
  { component: SnackbarProvider, props: {} },
  { component: RoutedDialogProvider, props: {} },
  { component: CustomTourProvider, props: {} },
  { component: SubscriptionProvider, props: {} },
  { component: ModalProvider, props: {} },
  { component: FeedbackContextProvider, props: {} },
  { component: InternetConnectivityProvider, props: {} },
  { component: OneSignalProvider, props: {} },
  { component: LocalizationProvider, props: { dateAdapter: AdapterDateFns } },
] as Provider[];

export const AppNestedProviders = combineComponents(...providers);
