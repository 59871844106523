import { Debate } from "./debate";
import { debates } from "./debates";
import { followedDebates } from "./followed-debates";

export const debateQueryFields = {
  debates,
  followedDebates,
};

export { Debate };
