import dynamic from "next/dynamic";
import React, { createContext, useState, ReactNode, useContext } from "react";

import type { SlackFeedbackDialogProps } from "./SlackFeedbackDialog/SlackFeedbackDialog";

const SlackFeedbackDialog = dynamic<SlackFeedbackDialogProps>(
  () =>
    import("./SlackFeedbackDialog/SlackFeedbackDialog").then(
      (module) => module.SlackFeedbackDialog,
    ),
  {
    ssr: false,
  },
);

type FeedbackContextType = {
  show: () => void;
};

const FeedbackContext = createContext<FeedbackContextType>({ show: () => {} });

export const FeedbackContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);

  const show = () => {
    setOpen(true);
  };

  return (
    <FeedbackContext.Provider value={{ show }}>
      <SlackFeedbackDialog open={open} onClose={() => setOpen(false)} />
      {children}
    </FeedbackContext.Provider>
  );
};

export const useShowSlackFeedback = () => useContext(FeedbackContext).show;
