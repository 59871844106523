import { FollowSortOrientation, useGetFollowedGroupsLazyQuery } from "@/graphql/types";

interface FetchFollowedGroupsOptions {
  sortBy: FollowSortOrientation;
  limit: number;
}

export const useGroupsFollowedStore = () => {
  const [
    fetchFollowedGroups,
    {
      loading: fetchFollowedLoading,
      data: dataFollowed,
      error: fetchFollowedError,
      fetchMore: fetchNextFollowedGroupsPage,
    },
  ] = useGetFollowedGroupsLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return {
    loading: fetchFollowedLoading,
    error: fetchFollowedError !== undefined,
    hasMorePages: dataFollowed?.followedGroups.pageInfo.hasNextPage || false,
    followedGroups: dataFollowed?.followedGroups.edges.map(({ node }) => node) || [],
    fetchFollowedGroups({ sortBy, limit }: FetchFollowedGroupsOptions) {
      return fetchFollowedGroups({
        variables: {
          sortBy,
          limit,
        },
      });
    },
    fetchNextFollowedGroupsPage() {
      if (fetchNextFollowedGroupsPage && dataFollowed) {
        return fetchNextFollowedGroupsPage({
          variables: {
            after: dataFollowed.followedGroups.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
