export const PREDEFINED_AVATAR_NAMES = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];

export const PREDEFINED_COVER_NAMES = [
  "1",
  "2",
  "z-monarchie",
  "Arthaud",
  "Dupont-Aignan",
  "Hidalgo",
  "Jadot",
  "Lassale",
  "Lepen",
  "Macron",
  "Melenchon",
  "Pecresse",
  "Poutou",
  "Roussel",
  "Zemour",
];
