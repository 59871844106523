// If you want to add a new feature flag, you have to add it to the FEATURE_FLAGS array (fe. ["TEST_FF", "FE_NEW_FEATURE_FLAG"])
export const FEATURE_FLAGS = [
  "FE_CHAT",
  "LIVE_STREAM",
  "LIVE_STREAM_CREATE",
  "CAPTCHA",
  "FE_CHAT_ATTACHMENTS",
  "FE_CHAT_LIKES",
  "FE_CHAT_REPLIES",
  "FE_CHAT_FORWARDS",
  "FE_CHAT_GROUPS",
  "FE_CHAT_BLOCK",
  "FE_CHAT_CLEAR",
  "FE_CHAT_REPORT",
  "FE_CHAT_MUTE",
  "FE_CHAT_MESSAGE_STATUS",
  "FE_COMMENT_ATTACHMENTS",
  "DEACTIVATE_ACCOUNT",
  "FE_COMMENTS_ORDER_OPTIONS",
  "FE_PROFILE_TIMELINE_CTA",
  "FE_CHAT_EMBEDS",
  "FE_HOMEPAGE_BLOG_LINK",
  "TWO_FACTOR_AUTH",
  "FE_LANDING_ANNOUNCEMENT",
] as const;

export type FeatureFlagName = typeof FEATURE_FLAGS[number];

// Those feature flags will be fetched always
export const DEFAULT_FEATURE_FLAGS: FeatureFlagName[] = [
  "FE_CHAT",
  "LIVE_STREAM",
  "LIVE_STREAM_CREATE",
  "CAPTCHA",
  "FE_COMMENT_ATTACHMENTS",
  "FE_COMMENTS_ORDER_OPTIONS",
  "FE_PROFILE_TIMELINE_CTA",
  "FE_HOMEPAGE_BLOG_LINK",
  "FE_LANDING_ANNOUNCEMENT",
];
