import React, { ReactNode } from "react";

interface TourHintContentProps {
  title: string;
  body: string | ReactNode;
  classes?: Record<"title" | "body", string>;
}

const TourHintContent = ({ title, body, classes }: TourHintContentProps) => {
  return (
    <>
      <h4 className={classes?.title}>{title}</h4>
      <div className={classes?.body}>{body}</div>
    </>
  );
};

export default TourHintContent;
