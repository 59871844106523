import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useState, ComponentProps, FC, CSSProperties } from "react";

import { Theme, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { StepType, Position } from "@reactour/tour";
import throttle from "lodash/throttle";
import { useTranslation } from "react-i18next";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useTourStore } from "@/tour/hooks";

import { TOUR_TOPICS_MAP } from "@/tour/constants";
import {
  doArrowStyles,
  DoArrowStylesProps,
  convertHintToSteps,
  ConvertHintsToStepsProps,
} from "@/tour/services";

import TourHint, { TourHintProps } from "./TourHint";
import TourHintContent from "./TourHintContent";
import TourWrapper from "./TourWrapper";

const useStyles = makeStyles()(() => ({
  mask: {
    opacity: "0 !important",
  },
}));

const TourProvider = dynamic<any>(
  () => import("@reactour/tour").then((module) => module.TourProvider),
  {
    ssr: false,
  },
);

const CustomTourProvider = ({ children }: ComponentProps<FC>) => {
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();
  const { session } = useAuthenticationStore();
  const { hints, loading, fetchHintSet, updateUserHintSets } = useTourStore();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { visitedHintSetTopics } = session?.user || {};
  const { topic, subtopic } = TOUR_TOPICS_MAP[router.pathname] || {};

  const handleAfterOpen = () => setIsOpen(true);
  const handleBeforeClose = () => setIsOpen(false);
  const handleTourClose = () => updateUserHintSets({ topic });

  const steps: StepType[] = convertHintToSteps({
    hints,
    topic,
    subtopic,
  } as ConvertHintsToStepsProps).map(({ selector, title, body }) => ({
    selector,
    mutationObservables: [".scrolling"],
    content: <TourHintContent title={t(title)} body={t(body)} />,
  }));

  useEffect(() => {
    const handleScroll = throttle(() => {
      setIsScrolling(true);
      setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    }, 500);

    isOpen && window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  useEffect(() => {
    if (session && topic && !visitedHintSetTopics?.includes(topic)) {
      setIsEnabled(true);
      fetchHintSet({ topic });
    } else {
      setIsEnabled(false);
    }
  }, [router.pathname, session]);

  return isEnabled ? (
    <TourProvider
      steps={steps}
      maskClassName={classes.mask}
      ContentComponent={(props: TourHintProps) => <TourHint {...props} onClose={handleTourClose} />}
      styles={{
        popover: (
          base: CSSProperties,
          state: { position: Position; verticalAlign: string; horizontalAlign: string },
        ) => ({
          ...base,
          width: isMobile ? "calc(100% - 32px)" : 250,
          padding: 16,
          borderRadius: 16,
          backgroundColor: theme.mode.background.snackbar,
          boxShadow: "none",
          ...doArrowStyles({
            ...state,
            isMobile,
            color: theme.mode.background.snackbar,
          } as DoArrowStylesProps),
        }),
      }}
      padding={{ popover: [16, isMobile ? 0 : -16] }}
      position={isMobile ? "bottom" : "right"}
      scrollSmooth
      afterOpen={handleAfterOpen}
      beforeClose={handleBeforeClose}>
      <>
        {isScrolling && <div className="scrolling"></div>}
        <TourWrapper loading={loading} steps={steps}>
          {children}
        </TourWrapper>
      </>
    </TourProvider>
  ) : (
    <>{children}</>
  );
};

export default CustomTourProvider;
