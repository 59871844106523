export const IN_FAVOR_AS_ANSWER: YesNo = "yes";

import { YesNo } from "@/common/models/Enums";
import { DebateFragment, VoteOrientation, useVoteDebateMutation } from "@/graphql/types";

export const useDebatesSimpleVoteStore = () => {
  const [vote] = useVoteDebateMutation();

  return {
    vote(debateId: string, orientation: VoteOrientation) {
      return vote({ variables: { id: debateId, vote: orientation } });
    },
  };
};

export const useDebatesVoteVariantStore = (debate: DebateFragment, variant: YesNo) => {
  const [vote, { loading }] = useVoteDebateMutation({
    variables: {
      id: debate.id,
      vote: variant === IN_FAVOR_AS_ANSWER ? VoteOrientation.InFavor : VoteOrientation.Against,
    },
  });

  const voteTimeline = (orientation: VoteOrientation) => {
    vote({ variables: { id: debate.id, vote: orientation } });
  };

  return {
    loading,
    vote,
    voteTimeline,
    get isMyVote() {
      const { votes } = debate;

      if (votes === undefined || votes === null) {
        return false;
      }

      if (variant === IN_FAVOR_AS_ANSWER) {
        return votes.voted === VoteOrientation.InFavor;
      }

      return votes.voted === VoteOrientation.Against;
    },
    get votesCount() {
      if (debate.votes) {
        if (variant === IN_FAVOR_AS_ANSWER) {
          return debate.votes?.inFavor;
        } else {
          return debate.votes?.against;
        }
      }
      return 0;
    },
    get totalVotes() {
      if (debate.votes) {
        return debate.votes.inFavor + debate.votes.against;
      }
      return 0;
    },
    get votesCountPercentage() {
      return this.totalVotes > 0 ? Math.round(100 * (this.votesCount / this.totalVotes)) : 0;
    },
    variantToVoteOrientation(variant: YesNo): VoteOrientation {
      if (variant === IN_FAVOR_AS_ANSWER) {
        return VoteOrientation.InFavor;
      }
      return VoteOrientation.Against;
    },
  };
};
