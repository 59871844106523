import {
  GroupCardFragment,
  GroupCardSlimFragment,
  GroupFragment,
  useReportGroupMutation,
} from "@/graphql/types";

export const useGroupsReportsStore = (
  group: GroupFragment | GroupCardFragment | GroupCardSlimFragment,
) => {
  const [report] = useReportGroupMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: group.id,
            type,
            comment,
          },
        }).then(({ data }) => {
          if (data?.reportGroup?.successful) {
            resolve(data?.reportGroup?.result);
          } else {
            reject(data?.reportGroup);
          }
        });
      });
    },
  };
};
