import { useRouter } from "next/router";
import React, { useEffect, ComponentProps, FC } from "react";

import { useTour, StepType } from "@reactour/tour";

import { TOUR_TOPICS_MAP, TOUR_APPEARANCE_DELAY } from "@/tour/constants";

const TourWrapper = ({
  children,
  loading,
  steps,
}: ComponentProps<FC> & { loading: boolean; steps: StepType[] }) => {
  const router = useRouter();
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const waitless = TOUR_TOPICS_MAP[router.pathname]?.waitless;

  useEffect(() => {
    setIsOpen(false);
    setCurrentStep(0);
    setSteps(steps);
    waitless && setTimeout(() => setIsOpen(!loading), TOUR_APPEARANCE_DELAY);
  }, [loading, setCurrentStep, setSteps]);

  return <>{children}</>;
};

export default TourWrapper;
