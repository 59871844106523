import { useGetUsersFollowedByQuery, UserFragment } from "@/graphql/types";

type UseProfileFollowingProps = {
  user?: UserFragment | null;
  limit: number;
};

export const useProfileFollowingStore = ({ user, limit }: UseProfileFollowingProps) => {
  const {
    loading,
    data,
    error,
    fetchMore: fetchNextFollowingPage,
  } = useGetUsersFollowedByQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !user,
    variables: {
      username: user?.username as string,
      limit,
    },
  });

  return {
    following: data?.user?.follows.peopleFollowedByThisUser.edges.map(({ node }) => node) || [],
    followingCount: data?.user?.follows.peopleFollowedByThisUser.count || 0,
    loading,
    initialized: !!data?.user?.follows,
    error: error !== undefined,
    hasMorePages: data?.user?.follows.peopleFollowedByThisUser.pageInfo.hasNextPage || false,
    fetchNextFollowingPage() {
      if (fetchNextFollowingPage && data?.user) {
        return fetchNextFollowingPage({
          variables: {
            after: data.user.follows.peopleFollowedByThisUser.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
