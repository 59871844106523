import { useApolloClient } from "@apollo/client";

import {
  ProfileInput,
  UsernameAvailableQuery,
  UsernameAvailableDocument,
  useCreateUserPersonalDataMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "@/graphql/types";

export const useProfileStore = (slug?: string) => {
  const apolloClient = useApolloClient();
  const updateProfileRefetchQueries = [
    "GetLiveStreams",
    "GetGroups",
    "GetPetitions",
    "GetEvents",
    "GetDebates",
    "GetCommunityTimelineItems",
    "DiscoverActivities",
    "DiscoverMoreActivities",
    "DiscoverPeople",
  ];

  const {
    loading: fetchLoading,
    data,
    error,
  } = useGetProfileQuery({
    variables: { slug: slug || "" },
    skip: !slug,
  });

  const [updateProfile, { loading: updateLoading, data: updateData }] = useUpdateProfileMutation({
    refetchQueries: updateProfileRefetchQueries,
  });
  const [requestPersonalData, { loading: requestPersonalDataLoading }] =
    useCreateUserPersonalDataMutation();

  return {
    get loading() {
      return fetchLoading || updateLoading;
    },
    get error() {
      if (error !== undefined) {
        return error;
      } else if (updateData?.updateProfile) {
        return !updateData.updateProfile.successful;
      }
    },
    profile: data?.user,
    update(data: ProfileInput, refetchQueries?: boolean) {
      return updateProfile({
        variables: {
          data,
        },
        refetchQueries: refetchQueries === false ? [] : updateProfileRefetchQueries,
      });
    },
    requestPersonalDataLoading,
    requestPersonalData() {
      return new Promise((resolve, reject) => {
        requestPersonalData().then(({ data }) => {
          if (data?.createPersonalData?.successful) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async isUserNameAvailable(username: string) {
      const result = await apolloClient.query<UsernameAvailableQuery>({
        query: UsernameAvailableDocument,
        variables: {
          username,
        },
      });

      return result.data.user === null;
    },
  };
};
