import { gql, TypePolicy } from "@apollo/client";

import {
  LiveStreamCommentFragmentDoc,
  LiveStreamFragmentDoc,
  PostCommentFragmentDoc,
} from "@/graphql/types";

export const Comment: TypePolicy = {
  fields: {
    url: {
      read(_, { readField, cache }) {
        let postUrl: string | undefined;
        try {
          const post = readField("post");
          const postRef = (post as any)?.__ref;
          const postFragment = postRef
            ? (cache.readFragment({
                id: postRef,
                fragment: gql`
                  fragment Post on Post {
                    id
                    url @client
                  }
                `,
              }) as { url: string } | undefined)
            : undefined;
          postUrl = postFragment?.url || "";
        } catch {}

        return postUrl ? `${postUrl}?commentId=${readField("id")}` : "";
      },
    },
  },
};

export const LiveStreamComment: TypePolicy = {
  fields: {
    url: {
      read(_, { readField, cache }) {
        let liveStreamUrl: string | undefined;
        try {
          const liveStream = readField("liveStream");
          const liveStreamRef = (liveStream as any)?.__ref;
          const liveStreamFragment = liveStreamRef
            ? (cache.readFragment({
                id: liveStreamRef,
                fragment: LiveStreamFragmentDoc,
              }) as { url: string } | undefined)
            : undefined;
          liveStreamUrl = liveStreamFragment?.url || "";
        } catch {}

        return liveStreamUrl ? `${liveStreamUrl}?commentId=${readField("id")}` : "";
      },
    },
  },
};

const commentTypeNameToDoc = (commentFragmentName: string) => {
  const fragments = {
    Comment: PostCommentFragmentDoc,
    LiveStreamComment: LiveStreamCommentFragmentDoc,
  };

  const fragmentName = commentFragmentName.split(":")[0];

  return fragments[fragmentName as keyof typeof fragments];
};

const commentTypeNameToFragmentName = (commentFragmentName: string) => {
  const fragmentNames = {
    Comment: "PostComment",
    LiveStreamComment: "LiveStreamComment",
  };

  const typeName = commentFragmentName.split(":")[0];

  return fragmentNames[typeName as keyof typeof fragmentNames];
};

export const CommentReply: TypePolicy = {
  fields: {
    url: {
      read(_, { readField, cache }) {
        let parentCommentUrl: string | undefined;
        try {
          const comment = readField("comment");
          const commentRef = (comment as any)?.__ref;
          const doc = commentTypeNameToDoc(commentRef);
          const fragmentName = commentTypeNameToFragmentName(commentRef);

          if (doc) {
            const commentFragment = commentRef
              ? (cache.readFragment({
                  id: commentRef,
                  fragment: doc,
                  fragmentName,
                }) as { url: string } | undefined)
              : undefined;
            parentCommentUrl = commentFragment?.url || "";
          }
        } catch {}

        return parentCommentUrl ? `${parentCommentUrl}&replyId=${readField("id")}` : "";
      },
    },
  },
};
