import { useState } from "react";

type InternalDialogState<Arguments> = {
  open: boolean;
  args?: Arguments | null;
  onClose?: (result: Arguments) => void;
};

type DialogState<Arguments> = {
  open: (args?: Arguments) => void;
  close: () => void;
  internalState: InternalDialogState<Arguments>;
};

export function useDialog<Arguments = any>(
  onClose?: (result?: Arguments) => void,
): DialogState<Arguments> {
  const [state, setState] = useState<InternalDialogState<Arguments>>({
    open: false,
    args: null,
    onClose,
  });

  return {
    internalState: state,
    open(args) {
      setState((prevState) => ({
        ...prevState,
        args,
        open: true,
      }));
    },
    close() {
      setState((prevState) => ({
        ...prevState,
        args: null,
        open: false,
      }));
    },
  };
}

export const bindDialogState = <Arguments = unknown>(state: DialogState<Arguments>) => ({
  open: state.internalState.open,
  args: state.internalState.args,
  onClose: (result?: Arguments) => {
    if (state.internalState.onClose && result) state.internalState.onClose(result);

    state.close();
  },
});
