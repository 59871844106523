import React, { ReactNode, ButtonHTMLAttributes } from "react";

import Link from "@/common/components/Link";

import { useStyles } from "./Button.styles";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  children: ReactNode;
  className?: string;
  href?: string;
  variant?:
    | "primary"
    | "secondary"
    | "inline-primary"
    | "inline-secondary"
    | "tertiary"
    | "text"
    | "formless";
  size?: "small" | "medium";
  uppercase?: boolean;
}

export const Button = ({
  children,
  variant,
  size,
  className,
  href,
  uppercase,
  ...delegated
}: ButtonProps) => {
  const { classes, cx } = useStyles({ uppercase: variant === "formless" ? false : uppercase });
  const variantClassName = classes[variant ?? "primary"];
  const sizeClassName = classes[size ?? "medium"];
  const isLink = !!href;
  const isInternalLink = isLink && href.startsWith("/");

  return isLink ? (
    isInternalLink ? (
      <Link href={href}>
        <a
          className={cx(classes.button, sizeClassName, variantClassName, className)}
          {...delegated}>
          {children}
        </a>
      </Link>
    ) : (
      <a
        className={cx(classes.button, sizeClassName, variantClassName, className)}
        href={href}
        {...delegated}>
        {children}
      </a>
    )
  ) : (
    <button
      className={cx(classes.button, sizeClassName, variantClassName, className)}
      {...delegated}>
      {children}
    </button>
  );
};
