import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { ReactNode, useEffect } from "react";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useCookieConsent, useDialog } from "@/common/hooks";

import { DEPLOY_ENV } from "../constants";

const CookiesBanner = dynamic(() => import("@/common/components/cookies-banner/CookiesBanner"), {
  ssr: false,
});

const Gdpr = (props: { children: ReactNode }) => {
  const { consent, consentCookieExists } = useCookieConsent();
  const { session } = useAuthenticationStore();
  const cookieConsentDialogState = useDialog();
  const router = useRouter();
  const userCountry =
    session?.user?.selectedCountries && session?.user?.selectedCountries[0] === "FR"
      ? "FR"
      : "OTHER";
  const userEmail = session?.user?.email || "";

  const disableConsent = router.query?.disableConsent?.toString().trim().toLowerCase() === "true";

  const showStatisticsCookies = !disableConsent && consent.statistics && DEPLOY_ENV === "PROD";

  useEffect(() => {
    if (router.pathname === "/privacy-policy") {
      cookieConsentDialogState.close();
    } else if (!consentCookieExists) {
      cookieConsentDialogState.open();
    }
  }, [router.pathname]);

  return (
    <>
      {showStatisticsCookies && (
        <Script id="outbrain_pixel_inline" strategy="afterInteractive">
          {`
            /** DO NOT MODIFY THIS CODE**/
            !function(_window, _document) {
              var OB_ADV_ID = '00d389b0095b27c63d8a99b7c36b4b439d';
              if (_window.obApi) {
                var toArray = function(object) {
                return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
                };
                _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
                return;
              }
              var api = _window.obApi = function() {
                api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
              };
              api.version = '1.1';
              api.loaded = true;
              api.marketerId = OB_ADV_ID;
              api.queue = [];
              var tag = _document.createElement('script');
              tag.async = true;
              tag.src = '//amplify.outbrain.com/cp/obtp.js';
              tag.type = 'text/javascript';
              var script = _document.getElementsByTagName('script')[0];
              script.parentNode.insertBefore(tag, script);
            }(window, document);
            
            obApi('track', 'PAGE_VIEW');
          `}
        </Script>
      )}
      {DEPLOY_ENV === "PROD" && !disableConsent && (
        <Script id="engagebay_inline" strategy="afterInteractive">
          {`
            var EhAPI = EhAPI || {}; EhAPI.after_load = function(){ EhAPI.set_account('dmrh24cj583gqp99fh6ngmai5', 'politicall'); EhAPI.execute('rules');};(function(d,s,f) { var sc=document.createElement(s);sc.type='text/javascript'; sc.async=true;sc.src=f;var m=document.getElementsByTagName(s)[0]; m.parentNode.insertBefore(sc,m); })(document, 'script', '//staticm.engagebay.com/jsapi/ehform.js');
          `}
        </Script>
      )}
      {showStatisticsCookies && (
        <Script id="twitter_pixel_inline" strategy="afterInteractive">
          {`
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o7avt');
            twq('track','PageView');
            console.log('Twitter pixel loaded.');
        `}
        </Script>
      )}
      {showStatisticsCookies && (
        <Script id="fb_pixel_inline" strategy="afterInteractive">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '623075692071867');
            fbq('init', '250764630595412'); 
            fbq('track', 'PageView');
            console.log('FB pixel loaded.');
        `}
        </Script>
      )}
      {showStatisticsCookies && (
        <Script id="smartlook_inline" strategy="afterInteractive">
          {`
            window.smartlook||(function(d) {
              var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
              var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
              c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
              })(document);
              smartlook('init', 'ec547a7fc389b47e90c571fe38080b9f4393d595', { region: 'eu' });
          `}
        </Script>
      )}
      {showStatisticsCookies && (
        <Script id="tiktok_inline" strategy="afterInteractive">
          {`
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              ttq.load('CGMN2DRC77U81RF87BL0');
              ttq.page();
            }(window, document, 'ttq');
          `}
        </Script>
      )}
      {showStatisticsCookies && (
        <Script id="snap_inline" strategy="afterInteractive">
          {`
            (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
              {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
              a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
              r.src=n;var u=t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r,u);})(window,document,
              'https://sc-static.net/scevent.min.js');

              snaptr('init', 'b33cdf61-9f97-4e7f-9624-ce2872096757', {
                'user_email': '${userEmail}'
              });
              snaptr('track', 'PAGE_VIEW');
          `}
        </Script>
      )}
      {DEPLOY_ENV === "PROD" && (
        <Script
          id="hs-script-loader"
          defer
          async
          type="text/javascript"
          strategy="afterInteractive"
          src="//js-eu1.hs-scripts.com/25148782.js"
        />
      )}

      {showStatisticsCookies && (
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=AW-11344424934"
          strategy="afterInteractive"
        />
      )}
      {showStatisticsCookies && (
        <Script
          async
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=UA-110256630-1`}
        />
      )}
      {showStatisticsCookies && (
        <Script id="ga_inline_PPNZS5L" strategy="afterInteractive">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PPNZS5L');
          `}
        </Script>
      )}
      {showStatisticsCookies && (
        <Script id="ga_inline" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
              dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('set',
            {
              'custom_map':{
                'dimension1': 'politicall_user_location'
              }
            }
          );
          gtag('config', 'UA-110256630-1', {
            'politicall_user_location': '${userCountry}',
            'anonymize_ip': true
          });
          gtag('config', 'AW-11344424934');
          console.log('Google analytics loaded.')
        `}
        </Script>
      )}
      {props.children}

      {!disableConsent && <CookiesBanner />}
    </>
  );
};

export default Gdpr;
