import { useEffect, useState } from "react";

import { debounceTime, filter, Subject } from "rxjs";

import { useScript } from "@/common/hooks/use-script";

import { appSettings } from "@/common/constants";
import { GooglePlaceLocation } from "@/common/models/GooglePlaceLocation";

import { getFormattedAddress } from "../services/geoLocation";

export const useLocation = () => {
  const { status: scriptStatus, runOnLoad } = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${appSettings.googleApiKey}&libraries=places`,
  );
  const [results, setResults] = useState<GooglePlaceLocation[]>([]);
  const [searchStream$] = useState(new Subject<string>());

  useEffect(() => {
    const subscription = searchStream$
      .pipe(
        debounceTime(300),
        filter((query) => query.length > 1),
      )
      .subscribe((input) => {
        runOnLoad(() => {
          const autoCompleteService = new window.google.maps.places.AutocompleteService();

          autoCompleteService.getPlacePredictions({ input }, (results) => {
            results && setResults(results);
          });
        });
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [scriptStatus]);

  return {
    results,
    fetch(input: string) {
      searchStream$.next(input);
    },
    geolocalize(option: GooglePlaceLocation): Promise<GooglePlaceLocation> | undefined {
      if (scriptStatus !== "loading" && option) {
        const { place_id: placeId } = option;

        return new Promise((resolve, reject) => {
          const geocodeService = new window.google.maps.Geocoder();

          geocodeService.geocode({ placeId }, (results, status) => {
            if (status === "OK" && results && results[0]) {
              const {
                place_id: auxPlaceId,
                geometry: { location },
                address_components,
              } = results[0];

              const countryCode = address_components.find((addrc) =>
                addrc.types.includes("country"),
              )?.short_name;

              const city = address_components.find((addrc) =>
                addrc.types.includes("locality"),
              )?.long_name;

              const province = address_components.find((addrc) =>
                addrc.types.includes("administrative_area_level_1"),
              )?.long_name;

              resolve({
                ...option,
                city,
                province,
                description: getFormattedAddress(address_components),
                place_id: placeId || auxPlaceId,
                location: {
                  lat: location.lat(),
                  lng: location.lng(),
                  country: countryCode || "",
                },
              });
            } else {
              reject(new Error(`Geocoder failed due to: ${status}`));
            }
          });
        });
      }
    },
  };
};
