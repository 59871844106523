import { useState, useEffect } from "react";

export const useScript = (src: string) => {
  const [status, setStatus] = useState<"ready" | "loading" | "error" | "idle">(
    src ? "loading" : "idle",
  );
  const [fnQueue, setFnQueue] = useState<(() => void)[]>([]);

  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }

    let script = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);

    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");

      document.body.appendChild(script);

      const setAttributeFromEvent = (event: Event) => {
        script?.setAttribute("data-status", event.type === "load" ? "ready" : "error");
      };

      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      const dataStatus = script.getAttribute("data-status");
      dataStatus && setStatus(dataStatus as "ready" | "loading" | "error");
    }

    const setStateFromEvent = (event: Event) => {
      if (event.type === "load") {
        fnQueue.forEach((fn) => fn());
        setFnQueue([]);

        return setStatus("ready");
      }
      setStatus("error");
    };

    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src, fnQueue]);

  return {
    status,
    runOnLoad: (fn: () => void) => {
      if (status === "ready") {
        fn();
      } else {
        setFnQueue((prev) => [...prev, fn]);
      }
    },
  };
};
