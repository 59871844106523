import { FollowSortOrientation, useGetFollowedEventsLazyQuery } from "@/graphql/types";

interface FetchFollowedEventsOptions {
  sortBy: FollowSortOrientation;
  limit: number;
}

export const useEventsFollowedStore = () => {
  const [
    fetchFollowedEvents,
    {
      loading: fetchFollowedLoading,
      data: dataFollowed,
      error: fetchFollowedError,
      fetchMore: fetchNextFollowedEventsPage,
    },
  ] = useGetFollowedEventsLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return {
    get followedEvents() {
      if (dataFollowed) {
        return dataFollowed.followedEvents.edges.map(({ node }) => node);
      }
      return [];
    },
    fetchFollowedEvents({ sortBy, limit }: FetchFollowedEventsOptions) {
      return fetchFollowedEvents({
        variables: {
          sortBy,
          limit,
        },
      });
    },
    fetchNextFollowedEventsPage() {
      if (fetchNextFollowedEventsPage && dataFollowed) {
        return fetchNextFollowedEventsPage({
          variables: {
            after: dataFollowed.followedEvents.pageInfo.endCursor,
          },
        });
      }
    },

    get loading() {
      return fetchFollowedLoading;
    },
    get error() {
      return fetchFollowedError !== undefined;
    },
    get hasMorePages() {
      if (dataFollowed) {
        return dataFollowed.followedEvents.pageInfo.hasNextPage;
      }
      return false;
    },
  };
};
