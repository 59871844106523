import { useRouter } from "next/router";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAuthenticationStore } from "@/authentication/hooks";

import "@/common/services/i18n";

const I18n = () => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { session } = useAuthenticationStore();

  useEffect(() => {
    if (session?.user?.language && router.locale) {
      /**
       * Setting language on app can produce values like "en_GB" or "fr_FR" instead
       * of "en" or "fr". We are normalizing it here to match our configured  i18n locales.
       */
      const allowedLanguages = new Set(["en", "fr"]);
      let normalizedLanguage = session.user.language.slice(0, 2).toLowerCase();

      if (!allowedLanguages.has(normalizedLanguage)) {
        normalizedLanguage = "en";
      }
      if (normalizedLanguage !== router.locale) {
        i18n.changeLanguage(normalizedLanguage);
        router.replace(router.asPath, undefined, { locale: normalizedLanguage, shallow: true });
      }
    } else {
      router.locale && i18n.changeLanguage(router.locale);
    }
  }, [session]);

  return null;
};

export default I18n;
