import { TypePolicy } from "@apollo/client";

export const LiveStream: TypePolicy = {
  fields: {
    url: {
      read(_, { readField }) {
        return `/livestreams/${readField("slug")}`;
      },
    },
    follows: {
      merge(existing = {}, incoming = {}) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
  },
};
