import { useUserActivitiesQuery } from "@/graphql/types";

export const useProfileActivitiesStore = ({
  userId,
  limit = 12,
}: {
  userId: string;
  limit?: number;
}) => {
  const { loading, data, fetchMore } = useUserActivitiesQuery({
    variables: { userId, limit },
  });

  return {
    loading,
    activities: data?.userActivities.edges?.map(({ node }) => node) || [],
    count: data?.userActivities.edges?.length || 0,
    hasMorePages: data?.userActivities?.pageInfo?.hasNextPage || false,
    fetchNextPage() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.userActivities.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
