import { FollowSortOrientation, useGetFollowedDebatesLazyQuery } from "@/graphql/types";

interface FetchFollowedDebatesOptions {
  sortBy: FollowSortOrientation;
  limit: number;
}

export const useDebatesFollowedStore = () => {
  const [
    fetchFollowedDebates,
    {
      loading: fetchFollowedLoading,
      data: dataFollowed,
      error: fetchFollowedError,
      fetchMore: fetchNextFollowedDebatesPage,
    },
  ] = useGetFollowedDebatesLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return {
    get followedDebates() {
      if (dataFollowed) {
        return dataFollowed.followedDebates.edges.map(({ node }) => node);
      }
      return [];
    },
    fetchFollowedDebates({ sortBy, limit }: FetchFollowedDebatesOptions) {
      return fetchFollowedDebates({
        variables: {
          sortBy,
          limit,
        },
      });
    },
    fetchNextFollowedDebatesPage() {
      if (fetchNextFollowedDebatesPage && dataFollowed) {
        return fetchNextFollowedDebatesPage({
          variables: {
            after: dataFollowed.followedDebates.pageInfo.endCursor,
          },
        });
      }
    },
    get loading() {
      return fetchFollowedLoading;
    },
    get error() {
      return fetchFollowedError !== undefined;
    },
    get hasMorePages() {
      if (dataFollowed) {
        return dataFollowed.followedDebates.pageInfo.hasNextPage;
      }
      return false;
    },
  };
};
