import React, { createContext, useState, ComponentProps, FC, useContext } from "react";

import Snackbar, { SnackbarProps } from "@/common/components/Snackbar";

interface SnackbarContextInterface {
  state: SnackbarProps;
  open: (state: SnackbarProps) => void;
  close: () => void;
}

export const SnackbarContext = createContext<SnackbarContextInterface>(
  {} as SnackbarContextInterface,
);

export const SnackbarProvider = ({ children }: ComponentProps<FC>) => {
  const [state, setState] = useState<SnackbarProps>({ open: false } as SnackbarProps);
  const open = (new_state: SnackbarProps) => state.open || setState(new_state);
  const close = () => setState({ open: false } as SnackbarProps);
  const handleClose = () => {
    close();
    state.onClose && state.onClose();
  };

  return (
    <SnackbarContext.Provider value={{ state, open, close }}>
      {children}
      <Snackbar {...state} onClose={handleClose} />
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SnackbarContext);
