import { Event } from "./event";
import { events } from "./events";
import { followedEvents } from "./followed-events";

export const eventQueryFields = {
  events,
  followedEvents,
};

export { Event };
