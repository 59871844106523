import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const User: TypePolicy = {
  fields: {
    url: {
      read(_, { readField }) {
        return `/profiles/${readField("slug")}`;
      },
    },
    follows: {
      merge(existing = {}, incoming = {}) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
    profilePosts: relayStylePagination(),
    initials: {
      read(_, { readField }) {
        const firstName = readField<string>("firstName");
        const lastName = readField<string>("lastName");
        const username = readField<string>("username");
        const showRealName = readField<boolean>("showRealName");

        if (showRealName) {
          if (firstName && lastName) {
            return `${firstName[0]}${lastName[0]}`.toLocaleUpperCase();
          }
        } else if (username) {
          return username[0].toLocaleUpperCase();
        }
        return "";
      },
    },
    displayName: {
      read(_, { readField }) {
        const fullName = readField<string>("fullName");
        const username = readField<string>("username");
        const showRealName = readField<boolean>("showRealName");

        if (showRealName) {
          return fullName;
        }
        return username;
      },
    },
  },
};
