import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import moment from "moment";

const transform = (datetime: string | undefined) => {
  const parsedDate = moment(datetime);
  return {
    date: parsedDate.format("YYYY-MM-DD"),
    time: parsedDate.format("HH:mm"),
  };
};

export const Event: TypePolicy = {
  fields: {
    url: {
      read(_, { readField }) {
        return `/events/${readField("slug")}`;
      },
    },
    follows: {
      merge(existing = {}, incoming = {}) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
    parsedStartTime: {
      read(_, { readField }) {
        return transform(readField("startTime"));
      },
    },
    parsedEndTime: {
      read(_, { readField }) {
        return transform(readField("endTime"));
      },
    },
    posts: relayStylePagination(["sortBy"]),
  },
};
