import { ItemsOrder, useGroupActivitiesItemsQuery } from "@/graphql/types";

export const useGroupsLinkedActivitiesStore = ({
  groupId,
  limit,
}: {
  groupId?: string;
  limit: number;
}) => {
  const { loading, data, fetchMore } = useGroupActivitiesItemsQuery({
    skip: !groupId,
    variables: { id: groupId as string, sortBy: ItemsOrder.NewestFirst, limit },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  return {
    count: data?.groupActivitiesItems.count,
    activities: data?.groupActivitiesItems.edges.map(({ node }) => node) || [],
    loading,
    hasMorePages: data?.groupActivitiesItems.pageInfo.hasNextPage || false,
    fetchNextActivitiesPage() {
      if (fetchMore && data?.groupActivitiesItems) {
        return fetchMore({
          variables: {
            after: data.groupActivitiesItems.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
