import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: "frontend_prod",
  denyUrls: ["https://acceptance.politicall.com/", "https://development.politicall.com/"],
  enabled: !!SENTRY_DSN,
});
