import {
  DebateCardFragment,
  DebateCardSlimFragment,
  DebateFragment,
  useReportDebateMutation,
} from "@/graphql/types";

export const useDebatesReportsStore = (
  debate: DebateFragment | DebateCardFragment | DebateCardSlimFragment,
) => {
  const [report] = useReportDebateMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: debate.id,
            type,
            comment,
          },
        }).then(({ data }: any) => {
          if (data?.reportDebate?.successful) {
            resolve(data?.reportDebate?.result);
          } else {
            reject(data?.reportDebate);
          }
        });
      });
    },
  };
};
