import NextLink, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";

import { UrlObject } from "url";

import { getLocalisedPath } from "@/common/services/locale";

type Override<T1, T2> = Pick<T1, Exclude<keyof T1, keyof T2>> & T2;
export type HrefProps = string | ({ pathname: string } & Omit<UrlObject, "pathname">);

/**
 * An abstraction over next/link to add support for locale paths rewrite redirects.
 */
const Link = ({
  href,
  externalLink,
  persistQueryParams,
  ...props
}: Override<LinkProps, { href: HrefProps }> & {
  children: ReactNode;
  externalLink?: boolean;
  persistQueryParams?: boolean;
}) => {
  const router = useRouter();

  const currentQuery = router.query;

  const redirectedHref =
    typeof href === "string"
      ? {
          pathname: getLocalisedPath(href, router.locale),
          query: persistQueryParams ? currentQuery : {},
        }
      : {
          ...href,
          pathname: getLocalisedPath(href.pathname, router.locale),
        };

  return (
    <NextLink
      prefetch={false}
      href={externalLink ? href : redirectedHref}
      locale={false}
      {...props}
    />
  );
};

export default Link;
