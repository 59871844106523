import { useSubscription } from "@apollo/client";

import {
  BlogFragment,
  GetBlogPostsQuery,
  GetBlogPostsQueryVariables,
  GetBlogPostsDocument,
  PostInput,
  PostsOrder,
  BlogAddedPostsCountDocument,
  useGetBlogPostsLazyQuery,
  useCreateBlogPostMutation,
} from "@/graphql/types";
import { PostsOrderOption } from "@/posts/models/PostsOrderOption";

export const useBlogsPostsStore = (blog: BlogFragment) => {
  const [fetchPosts, { data: postsData, networkStatus, fetchMore: fetchNextPostsPage, refetch }] =
    useGetBlogPostsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    });
  const { data: unreadPostsCountData } = useSubscription(BlogAddedPostsCountDocument, {
    variables: { id: blog.id, lastSeenPostId: postsData?.blog?.posts.edges[0]?.node.id },
    skip: networkStatus === 1,
  });
  const [createPost] = useCreateBlogPostMutation();

  return {
    posts: postsData?.blog?.posts.edges.map(({ node }) => node) || [],
    unreadPostsCount: unreadPostsCountData?.blogAddedPostsCount || 0,
    loading: networkStatus === 1,
    loadingRefetch: networkStatus === 4,
    hasMorePages: postsData?.blog?.posts.pageInfo.hasNextPage || false,
    fetchPosts({ order }: { order?: PostsOrderOption }) {
      return fetchPosts({
        variables: {
          id: blog.id,
          sortBy:
            order === PostsOrderOption.MostRecent ? PostsOrder.NewestFirst : PostsOrder.OldestFirst,
        },
      });
    },
    refetch() {
      return refetch && refetch();
    },
    createPost(data: PostInput) {
      return createPost({
        variables: {
          blogId: blog.id,
          data,
        },
        update: (cache, { data }) => {
          if (!data?.createBlogPost?.result) return;
          const result = cache.readQuery<GetBlogPostsQuery>({
            query: GetBlogPostsDocument,
            variables: {
              id: blog.id,
              sortBy: PostsOrder.NewestFirst,
            },
          });

          if (result && result.blog) {
            const newData: GetBlogPostsQuery = {
              ...result,
              blog: {
                ...result.blog,
                posts: {
                  ...result.blog.posts,
                  edges: [{ node: data.createBlogPost.result }, ...result.blog.posts.edges],
                },
              },
            };
            cache.writeQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>({
              query: GetBlogPostsDocument,
              variables: {
                id: blog.id,
                sortBy: PostsOrder.NewestFirst,
              },
              data: newData,
            });
          }
        },
      });
    },
    fetchNextPostsPage() {
      if (fetchNextPostsPage && postsData?.blog) {
        return fetchNextPostsPage({
          variables: {
            after: postsData.blog.posts.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
