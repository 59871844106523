import { useAuthenticationStore } from "@/authentication/hooks";

import {
  PetitionInput,
  GetPetitionsQuery,
  GetPetitionsQueryVariables,
  GetPetitionsDocument,
  UserFullFragment,
  useGetPetitionQuery,
  useGetPetitionsQuery,
  useCreatePetitionMutation,
  useUpdatePetitionMutation,
  useDeletePetitionMutation,
  useSignPetitionMutation,
  SignPetitionMutationVariables,
} from "@/graphql/types";

type UsePetitionsStoreProps = {
  slug?: string;
  fetch?: boolean;
  variables?: {
    user?: UserFullFragment;
    limit: number;
  };
  fetchBySlug?: boolean;
};

export const usePetitionsStore = ({
  slug,
  fetch,
  variables,
  fetchBySlug,
}: UsePetitionsStoreProps = {}) => {
  const { session } = useAuthenticationStore();
  const {
    loading: fetchLoading,
    data,
    error: fetchError,
  } = useGetPetitionQuery({ skip: !slug, variables: { slug: slug as string } });

  const {
    loading: fetchAllLoading,
    data: dataAll,
    error: fetchAllError,
    fetchMore: fetchNextPetitionsPage,
  } = useGetPetitionsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !fetch,
    variables: variables
      ? fetchBySlug
        ? {
            authorSlug: variables.user
              ? variables.user.slug
              : session?.user
              ? session.user.slug
              : "politicall",
            limit: variables.limit,
          }
        : {
            authorId: variables.user
              ? variables.user.id
              : session?.user
              ? session.user.id
              : "politicall",
            limit: variables.limit,
          }
      : undefined,
  });

  const [createPetition, { loading: createLoading }] = useCreatePetitionMutation();
  const [updatePetition, { loading: updateLoading }] = useUpdatePetitionMutation();
  const [deletePetition, { loading: deleteLoading }] = useDeletePetitionMutation();

  const [signPetition] = useSignPetitionMutation({ refetchQueries: ["GetPetition"] });

  return {
    get petition() {
      if (data) {
        return data.petition;
      }
    },
    get petitions() {
      if (dataAll) {
        return dataAll.petitions.edges.map(({ node }) => node);
      }
      return [];
    },
    fetchNextPetitionsPage() {
      if (fetchNextPetitionsPage && dataAll) {
        return fetchNextPetitionsPage({
          variables: {
            after: dataAll.petitions.pageInfo.endCursor,
          },
        });
      }
    },
    sign(data: SignPetitionMutationVariables) {
      return signPetition({
        variables: {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          anonymous: data.anonymous,
        },
      });
    },
    create(data: PetitionInput) {
      return createPetition({
        variables: {
          data,
        },
        update(cache, { data }) {
          if (session && session.user) {
            const result = cache.readQuery<GetPetitionsQuery, GetPetitionsQueryVariables>({
              query: GetPetitionsDocument,
              variables: {
                limit: 6,
                authorId: session.user.id,
              },
            });

            if (result) {
              cache.writeQuery({
                query: GetPetitionsDocument,
                variables: {
                  limit: 6,
                  authorId: session.user.id,
                },
                data: {
                  ...result,
                  petitions: {
                    ...result.petitions,
                    edges: [
                      ...result.petitions.edges,
                      {
                        node: data?.createPetition?.result,
                      },
                    ],
                  },
                },
              });
            }
          }
        },
      }).then(({ data }) => data?.createPetition?.result);
    },
    update(blogId: string, data: PetitionInput) {
      return updatePetition({
        variables: {
          id: blogId,
          data,
        },
      });
    },
    destroy(petitionId: string) {
      return deletePetition({
        variables: {
          id: petitionId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: petitionId,
            __typename: "Petition",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    get loading() {
      return fetchAllLoading || fetchLoading || createLoading || updateLoading || deleteLoading;
    },
    get error() {
      return fetchError !== undefined || fetchAllError !== undefined;
    },
    get hasMorePages() {
      if (dataAll) {
        return dataAll.petitions.pageInfo.hasNextPage;
      }
      return false;
    },
  };
};
