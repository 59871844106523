import { useAuthenticationStore } from "@/authentication/hooks";

import {
  BlogInput,
  GetBlogsQuery,
  GetBlogsQueryVariables,
  GetBlogsDocument,
  UserFullFragment,
  useGetBlogsQuery,
  useCreateBlogMutation,
  useDeleteBlogMutation,
} from "@/graphql/types";

type UseBlogsStoreProps = {
  fetch?: boolean;
  variables?: {
    user?: UserFullFragment;
    limit: number;
  };
  fetchBySlug?: boolean;
};

export const useBlogsStore = ({ fetch, variables, fetchBySlug }: UseBlogsStoreProps = {}) => {
  const { session } = useAuthenticationStore();
  const {
    loading: fetchAllLoading,
    data: blogsData,
    error: fetchAllError,
    fetchMore: fetchNextBlogsPage,
  } = useGetBlogsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !fetch,
    variables: variables
      ? fetchBySlug
        ? {
            authorSlug: variables.user
              ? variables.user.slug
              : session?.user
              ? session.user.slug
              : "politicall",
            limit: variables.limit,
          }
        : {
            authorId: variables.user
              ? variables.user.id
              : session?.user
              ? session.user.id
              : "politicall",
            limit: variables.limit,
          }
      : undefined,
  });

  const [createBlog, { loading: createLoading }] = useCreateBlogMutation();
  const [deleteBlog, { loading: deleteLoading }] = useDeleteBlogMutation();

  return {
    blogs: blogsData?.blogs.edges.map(({ node }) => node) || [],
    loading: fetchAllLoading || createLoading || deleteLoading,
    error: fetchAllError !== undefined,
    hasMorePages: blogsData?.blogs.pageInfo.hasNextPage || false,
    fetchNextBlogsPage() {
      if (fetchNextBlogsPage && blogsData) {
        return fetchNextBlogsPage({
          variables: {
            after: blogsData.blogs.pageInfo.endCursor,
          },
        });
      }
    },
    create(data: BlogInput) {
      return createBlog({
        variables: {
          data,
        },
        update(cache, { data }) {
          if (session && session.user) {
            const result = cache.readQuery<GetBlogsQuery, GetBlogsQueryVariables>({
              query: GetBlogsDocument,
              variables: {
                limit: 6,
                authorId: session.user.id,
              },
            });

            if (result) {
              cache.writeQuery({
                query: GetBlogsDocument,
                variables: {
                  limit: 6,
                  authorId: session.user.id,
                },
                data: {
                  ...result,
                  blogs: {
                    ...result.blogs,
                    edges: [
                      ...result.blogs.edges,
                      {
                        node: data?.createBlog?.result,
                      },
                    ],
                  },
                },
              });
            }
          }
        },
      }).then(({ data }) => data?.createBlog?.result);
    },
    destroy(blogId: string) {
      return deleteBlog({
        variables: {
          id: blogId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: blogId,
            __typename: "Blog",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
  };
};
