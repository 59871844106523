import { useReportUserMutation } from "@/graphql/types";

export const useProfileReportsStore = (profileId?: string) => {
  const [report] = useReportUserMutation();

  return {
    report({ type, comment }: any) {
      if (profileId) {
        return new Promise((resolve, reject) => {
          report({
            variables: {
              id: profileId,
              type,
              comment,
            },
          }).then(({ data }) => {
            if (data?.reportUser?.successful) {
              resolve(data?.reportUser?.result);
            } else {
              reject(data?.reportUser);
            }
          });
        });
      }
    },
  };
};
