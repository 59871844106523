import { useContext } from "react";

import Cookies from "js-cookie";

import { COOKIE_CONSENT_KEY } from "@/common/constants/cookieConsent";
import {
  CookieConsent,
  CookieConsentHookState,
  CookieWrapper,
  DidDeclineAllHandler,
} from "@/common/models/CookieConsentTypes";
import { ConsentContext } from "@/common/providers";
import { allCookiesSetToValue, allPropsApproved } from "@/common/services/cookiesConsentUtils";

export const useCookieConsent = (): CookieConsentHookState => {
  const { consent, setConsent } = useContext(ConsentContext);

  const updateConsent = (state: CookieConsent) => {
    if (state.necessary) {
      setConsent(state);
      Cookies.set(COOKIE_CONSENT_KEY, state, {
        sameSite: "Strict",
        expires: 365,
      });
    }
  };

  const consentCookieExists = Cookies.get(COOKIE_CONSENT_KEY) !== undefined;

  const acceptCookies = (newConsent: CookieConsent) => {
    updateConsent(newConsent);
  };

  const declineAllCookies = () => {
    updateConsent(allCookiesSetToValue(false));
  };

  const acceptAllCookies = () => {
    updateConsent(allCookiesSetToValue(true));
  };

  const didAcceptAll = () => {
    const keyArray = Object.keys(consent || {}) as (keyof CookieConsent)[];

    return keyArray.reduce((prev, key) => (prev && consent && consent[key] ? true : false), true);
  };

  const didDeclineAll: DidDeclineAllHandler = (opts) => {
    const keyArray = Object.keys(consent || {}) as (keyof CookieConsent)[];

    return keyArray.reduce<boolean>((prev, key): boolean => {
      if (!opts?.includingNecessary && key === "necessary") return prev;
      return (prev && consent && !consent[key]) || false;
    }, true);
  };

  const cookieWrapper: CookieWrapper = {
    set: (name, value, props, options) => {
      if (!allPropsApproved(props, consent)) return undefined;
      return Cookies.set(name, value, options);
    },
    get: Cookies.get,
    getAll: Cookies.get,
    getJSON: Cookies.getJSON,
    getAllJSON: Cookies.getJSON,
    remove: Cookies.remove,
  };

  return {
    consent,
    consentCookieExists,
    acceptCookies,
    declineAllCookies,
    acceptAllCookies,
    didAcceptAll,
    didDeclineAll,
    cookies: cookieWrapper,
  };
};
