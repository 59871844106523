import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { createContext, useState, FC, useContext, useEffect } from "react";

import { DonationDialogProps } from "@/monetization/DonationDialog";
import { PlanDialogProps } from "@/monetization/PlanDialog";

type SubscriptionContextProps = {
  open: () => void;
  close: () => void;
  openDonation: () => void;
  closeDonation: () => void;
  setVariant: (variant: "discover" | "profile" | "settings" | "cancel") => void;
  setDonationModalVariant: (
    variant: "discover" | "profile" | "settings" | "cancel" | "editRecurring" | "oneTimeOnly",
  ) => void;
  isOpen: boolean;
  isDonationOpen: boolean;
  dialogVariant: "discover" | "profile" | "settings" | "cancel";
  voteOrientation?: string;
};

export const SubscriptionContext = createContext<SubscriptionContextProps>(
  {} as SubscriptionContextProps,
);
const PlanDialog = dynamic<PlanDialogProps>(
  () => import("@/monetization/PlanDialog").then((module) => module.default),
  {
    ssr: false,
  },
);

const DonationDialog = dynamic<DonationDialogProps>(
  () => import("@/monetization/DonationDialog").then((module) => module.default),
  {
    ssr: false,
  },
);

export const SubscriptionProvider: FC = ({ children }) => {
  const router = useRouter();
  const { payment_intent_client_secret, redirect_status, payment_type } = router.query;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDonationOpen, setIsDonationOpen] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] = useState<
    "discover" | "profile" | "settings" | "cancel"
  >("discover");
  const [donationDialogVariant, setDonationDialogVariant] = useState<
    "discover" | "profile" | "settings" | "cancel" | "editRecurring" | "oneTimeOnly"
  >("discover");

  const setVariant = (variant: "discover" | "profile" | "settings" | "cancel") => {
    setDialogVariant(variant);
  };

  const setDonationModalVariant = (
    variant: "discover" | "profile" | "settings" | "cancel" | "editRecurring" | "oneTimeOnly",
  ) => {
    setDonationDialogVariant(variant);
  };

  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };
  const openDonation = () => {
    setIsDonationOpen(true);
  };
  const closeDonation = () => {
    setIsDonationOpen(false);
    setDonationDialogVariant("discover");
  };

  useEffect(() => {
    if (
      payment_intent_client_secret &&
      payment_type &&
      redirect_status &&
      !isOpen &&
      !isDonationOpen
    ) {
      const parsedPaymentType = Array.isArray(payment_type) ? payment_type[0] : payment_type;
      if (parsedPaymentType === "subscribe") open();
      if (parsedPaymentType === "donate") openDonation();
    }
  }, [payment_intent_client_secret, payment_type]);

  return (
    <SubscriptionContext.Provider
      value={{
        open,
        close,
        openDonation,
        closeDonation,
        isOpen,
        isDonationOpen,
        dialogVariant,
        setVariant,
        setDonationModalVariant,
      }}>
      <>
        <PlanDialog variant={dialogVariant} isOpen={isOpen} onClose={() => close()} />
        <DonationDialog
          variant={donationDialogVariant}
          isOpen={isDonationOpen}
          onClose={() => closeDonation()}
        />
        {children}
      </>
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionProvider = () => useContext(SubscriptionContext);
