import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const chatUsers: TypePolicy = relayStylePagination(["agreedBlocking"]);

export const ChatUser: TypePolicy = {
  fields: {
    url: {
      read(_, { readField }) {
        return `/profiles/${readField("slug")}`;
      },
    },
    displayName: {
      read(_, { readField }) {
        const fullName = readField<string>("fullName");
        const username = readField<string>("username");
        const showRealName = readField<boolean>("showRealName");

        if (showRealName) {
          return fullName;
        }
        return username;
      },
    },
  },
};
