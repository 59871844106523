import { useApolloClient } from "@apollo/client";

import {
  BlockUserMutationVariables,
  UnblockUserMutationVariables,
  useBlockUserMutation,
  useUnblockUserMutation,
  useListBlockedUsersLazyQuery,
} from "@/graphql/types";

interface FetchBlockedUsersOptions {
  limit: number;
}

export const useProfilePrivacyStore = () => {
  const apolloClient = useApolloClient();
  const [
    fetchBlockedUsers,
    { data: blockedUsersData, loading, fetchMore: fetchNextBlockedUsersPage },
  ] = useListBlockedUsersLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [block, { loading: blockLoading }] = useBlockUserMutation();
  const [unblock, { loading: unblockLoading }] = useUnblockUserMutation();

  return {
    loading,
    blockLoading,
    unblockLoading,
    hasMoreBlockedUsers: blockedUsersData?.listBlockedUsers.pageInfo.hasNextPage || false,
    blockedUsers: blockedUsersData?.listBlockedUsers.edges?.map(({ node }) => node) || [],
    fetchBlockedUsers({ limit }: FetchBlockedUsersOptions) {
      return fetchBlockedUsers({
        variables: {
          limit,
        },
      });
    },
    fetchNextBlockedUsersPage() {
      if (fetchNextBlockedUsersPage && blockedUsersData) {
        return fetchNextBlockedUsersPage({
          variables: {
            after: blockedUsersData.listBlockedUsers.pageInfo.endCursor,
          },
        });
      }
    },
    block({
      userId,
      groupChatId,
      resetStore = true,
    }: BlockUserMutationVariables & { resetStore?: boolean }) {
      if (userId) {
        return block({
          variables: { userId, groupChatId },
          update() {
            if (resetStore) apolloClient.resetStore();
          },
        });
      }
    },
    unblock({ userId, groupChatId }: UnblockUserMutationVariables) {
      if (userId) {
        return unblock({
          variables: { userId, groupChatId },
          update() {
            apolloClient.resetStore();
          },
        });
      }
    },
  };
};
