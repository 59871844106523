import {
  BlogCardFragment,
  BlogCardSlimFragment,
  BlogFragment,
  useReportBlogMutation,
} from "@/graphql/types";

export const useBlogsReportsStore = (
  blog: BlogFragment | BlogCardFragment | BlogCardSlimFragment,
) => {
  const [report] = useReportBlogMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: blog.id,
            type,
            comment,
          },
        }).then(({ data }) => {
          if (data?.reportBlog?.successful) {
            resolve(data?.reportBlog?.result);
          } else {
            reject(data?.reportBlog);
          }
        });
      });
    },
  };
};
