import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const Group: TypePolicy = {
  fields: {
    url: {
      read(_, { readField }) {
        return `/groups/${readField("slug")}`;
      },
    },
    posts: relayStylePagination(["sortBy"]),
    follows: {
      merge(existing = {}, incoming = {}) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
  },
};

export const groupTimelineItems: TypePolicy = relayStylePagination(["sortBy"]);
