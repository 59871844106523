import { useApolloClient } from "@apollo/client";

import { YesNo } from "@/common/models/Enums";
import {
  DebateFragment,
  PostInput,
  GetDebateQuery,
  GetDebateQueryVariables,
  GetDebateDocument,
  useCreateDebatePostMutation,
  useGetAllDebatePostsQuery,
  PostsOrder,
  GetAllDebatePostsQuery,
  GetAllDebatePostsDocument,
  GetAllDebatePostsQueryVariables,
} from "@/graphql/types";

import { useDebatesVoteVariantStore } from "./use-debates-vote-variant-store";

export const useDebatesPostsStore = (debate: DebateFragment, variant?: YesNo) => {
  const { variantToVoteOrientation } = useDebatesVoteVariantStore(debate, variant || "no");
  const apolloClient = useApolloClient();

  const { data, networkStatus, fetchMore, refetch } = useGetAllDebatePostsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: debate.id,
      first: 10,
      sortBy: PostsOrder.NewestFirst,
    },
  });

  const [createPost] = useCreateDebatePostMutation();

  return {
    posts: (data?.debate?.posts && data?.debate?.posts.edges.map(({ node }) => node)) || [],
    unreadPostsCount: 0,
    hasMorePages: (data?.debate?.posts && data?.debate?.posts.pageInfo.hasNextPage) || false,
    loading: networkStatus === 1,
    loadingRefetch: networkStatus === 4,
    refetch(order: PostsOrder) {
      return refetch && refetch({ sortBy: order });
    },
    fetchNextPostsPage() {
      if (data?.debate?.posts) {
        return fetchMore({
          variables: {
            after: data.debate.posts.pageInfo.endCursor,
          },
        });
      }
    },
    createPost(data: PostInput, variant?: YesNo) {
      const voteVariant = variant ? variantToVoteOrientation(variant) : undefined;

      return createPost({
        variables: {
          debateId: debate.id,
          data: {
            ...data,
            debateVoteOrientation: data.debateVoteOrientation || voteVariant,
          },
        },
        update: (cache, { data }) => {
          if (!data?.createDebatePost?.result) return;

          apolloClient.refetchQueries({
            include: [GetAllDebatePostsDocument],
          });

          const result = cache.readQuery<GetAllDebatePostsQuery>({
            query: GetAllDebatePostsDocument,
            variables: {
              id: debate.id,
            },
          });

          if (result?.debate?.posts) {
            const newData: GetAllDebatePostsQuery = {
              ...result,
              debate: {
                ...result.debate,
                posts: {
                  ...result.debate.posts,
                  edges: [{ node: data.createDebatePost.result }, ...result.debate.posts.edges],
                },
              },
            };

            cache.writeQuery<GetAllDebatePostsQuery, GetAllDebatePostsQueryVariables>({
              query: GetAllDebatePostsDocument,
              variables: {
                id: debate.id,
                first: 10,
                sortBy: PostsOrder.NewestFirst,
              },
              data: newData,
            });
          }

          const cachedDebateData = cache.readQuery<GetDebateQuery>({
            query: GetDebateDocument,
            variables: {
              slug: debate.slug as string,
            },
          });

          if (cachedDebateData?.debate) {
            cache.writeQuery<GetDebateQuery, GetDebateQueryVariables>({
              query: GetDebateDocument,
              variables: {
                slug: debate.slug as string,
              },
              data: {
                ...cachedDebateData,
                debate: {
                  ...cachedDebateData.debate,
                  userAlreadyPosted: true,
                },
              },
            });
          }
        },
      });
    },
  };
};
