import { followedGroups } from "./followed-groups";
import { Group, groupTimelineItems } from "./group";
import { groupActivitiesItems } from "./group-activities";
import { groups } from "./groups";

export const groupQueryFields = {
  groups,
  followedGroups,
  groupTimelineItems,
  groupActivitiesItems,
};

export { Group };
